import { useState, useCallback, useContext, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import BaseHeader from 'components/Header/BaseHeader';
import {
  AskQuestionIcon,
  ChainLinkIcon,
  PaperPlaneIconAlt,
  StarFilledIcon,
  WhiteLogoWhiteContentIcon,
} from 'components/Icons';
import closeImg from 'assets/images/close-circle.svg';
import styles from './styles';
import { formatPrice } from 'utils/currency-utils';
import { AppDataContext } from 'Context';
import AsqMeCreatorHeader from 'components/Header/AsqMeCreatorHeader';
import { QuestionInputWithAttachment } from './inputs';
import { QuestionCreatorAwayCard, QuestionPriceCard, QuestionTipPriceCard } from './price-cards';
import { SponsoredCard } from 'components/Sponsor/SponsorCard';
import { slideoutTransitionDelay } from 'config/ui';
import { useAlert } from 'react-alert';
import { trackAppCtaAudienceClick } from 'store/customer';
import { getPathAndPageName } from 'utils/url-utils';
import { uploadErrorStatus } from 'hooks/upload';
import AppTextField from 'components/Input/AppTextField';
import { MetaPixel, GoogleTagManager } from './sponsor-analytics';
import EmailCheckbox from './EmailCheckbox';

const useStyles = makeStyles(styles);

const ProfilePreview = ({
  amountOptionEnabled,
  tipOptionEnabled,
  sponsorQnaEnabled,
  isAway,
  inPreviewMode,
  imageUploadDisabled = false,
  onPreviewClose,
  creatorData,
  customerData,
  onSave,
  saveIsLoading,
}) => {
  const classes = useStyles();

  const alert = useAlert();

  const { blue } = useTheme();

  const { convertAndFormatPrice } = useContext(AppDataContext);

  const [attachmentUploadIsLoading, setAttachmentUploadIsLoading] = useState(false);

  const {
    logo_image,
    logo_file,
    display_name,
    short_description,
    asqMe_tag,
    price_per_question,
    price_per_question_custom,
    currency_code,
    rating,
    sponsor,
  } = creatorData || {};

  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [showEmailNotCheckedError, setShowEmailNotCheckedError] = useState(false);

  const { getFieldProps, errors, touched, handleBlur, handleChange, handleSubmit, values } =
    useFormik({
      initialValues: {
        ...customerData,
      },
      validationSchema: Yup.object({
        customer_message: Yup.string()
          .required('Question is required')
          .min(3, 'Question must be at least 3 characters'),
        customer_name: Yup.string()
          .required('Name is required')
          .min(3, 'Name must be at least 3 characters'),
        customer_email: Yup.string().required('Email is required').email('Must be a valid email'),
      }),
      onSubmit: (data) => {
        if (!isEmailChecked) {
          setShowEmailNotCheckedError(true);
          return;
        }

        if (onSave) onSave(data);
      },
    });

  const handleTrimOnBlur = useCallback(
    (e) => {
      e.target.value = e.target.value.trim();
      handleChange(e);
      handleBlur(e);
    },
    [handleChange, handleBlur],
  );

  const handleFormImageChange = useCallback(
    (formNames) => (formData) => {
      trackAppCtaAudienceClick({
        ...getPathAndPageName(),
        cta_verbiage: 'Q Page Upload',
        anonymous_id: customerData.customer_anonymous_id,
      });
      const isVideoUploaded = formData.fileType.split('/')[0] === 'video';
      // image related updates
      handleChange({
        target: {
          name: formNames.image_file,
          value: isVideoUploaded ? '' : formData.encodedFile,
        },
      });
      handleChange({
        target: {
          name: formNames.image_url,
          value: isVideoUploaded ? '' : formData.fileName,
        },
      });
      // video related updates
      handleChange({
        target: {
          name: formNames.video_file,
          value: isVideoUploaded ? formData.encodedFile : '',
        },
      });
      handleChange({
        target: {
          name: formNames.video_url,
          value: isVideoUploaded ? formData.fileName : '',
        },
      });
    },
    [],
  );

  const handleFormRemoveAttachments = useCallback(() => {
    handleChange({
      target: {
        name: 'customer_attachment_image_encoded',
        value: '',
      },
    });
    handleChange({
      target: {
        name: 'customer_attachment_image_url',
        value: '',
      },
    });
    handleChange({
      target: {
        name: 'customer_attachment_video_encoded',
        value: '',
      },
    });
    handleChange({
      target: {
        name: 'customer_attachment_video_url',
        value: '',
      },
    });
  }, [handleChange]);

  const handleFormAttachmentError = (error) => {
    if (error === uploadErrorStatus.tooManyAttachments) {
      trackAppCtaAudienceClick({
        ...getPathAndPageName(),
        cta_verbiage: 'Q Page Upload Error - Multiple Attachments',
        anonymous_id: customerData.customer_anonymous_id,
      });
    } else if (error === uploadErrorStatus.invalidAttachmentType) {
        trackAppCtaAudienceClick({
          ...getPathAndPageName(),
          cta_verbiage: 'Q Page Upload Error - File Type',
          anonymous_id: customerData.customer_anonymous_id,
        });
    } else if (error === uploadErrorStatus.maxSizeExceeded) {
      trackAppCtaAudienceClick({
        ...getPathAndPageName(),
        cta_verbiage: 'Q Page Upload Error - Max File Size Exceeded',
        anonymous_id: customerData.customer_anonymous_id,
      });
    } else {
      trackAppCtaAudienceClick({
        ...getPathAndPageName(),
        cta_verbiage: 'Q Page Upload Error - Server Error',
        anonymous_id: customerData.customer_anonymous_id,
      });
    }
  }

  const questionPrice = useMemo(
    () =>
      !isNaN(parseInt(price_per_question))
        ? formatPrice(price_per_question, {
            currency: 'USD',
            appendCurrency: false,
            hideDecimals: true,
          })
        : !isNaN(parseInt(price_per_question_custom))
        ? convertAndFormatPrice(price_per_question_custom, {
            fromCurrency: currency_code,
            roundUp: true,
          })
        : '_',
    [],
  );

  return (
    <Grid container className={classes.previewContainer} alignContent="flex-start" justifyContent='center'>
      {sponsorQnaEnabled && (
        <>
          {sponsor?.analytics_type === 'facebook_pixel' && <MetaPixel pixelId={sponsor?.analytics_code} />}
          {sponsor?.analytics_type === 'google_analytics' && <GoogleTagManager gtmId={sponsor?.analytics_code} />}
        </>
      )}
      {/* header */}
      {inPreviewMode && <BaseHeader
        className={classes.header}
        leftIcon={<WhiteLogoWhiteContentIcon />}
        centerElement={
          <div className={classes.previewHeaderText}>PREVIEWING PROFILE</div>
        }
        hideRightIcon={false}
        rightIcon={<img src={closeImg} onClick={onPreviewClose} alt="Close icon" />}
      />}
      <Grid item xs={12} className={classes.headerContainer}>
        <AsqMeCreatorHeader title={display_name} iconUrl={logo_file || logo_image} showLogo={!inPreviewMode} />
      </Grid>
      <Grid container item className={classes.innerContainer}>
        <form onSubmit={handleSubmit} className={classes.formContainer}>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            className={classes.drawerContainer}
          >
            {/* creator intro */}
            <Grid container item className={classes.introSectionContainer} justifyContent='center'>
              {rating && <Grid container item justifyContent='center' alignItems='center' className={classes.ratingContainer}>
                <StarFilledIcon />
                <span>{rating}</span>
              </Grid>}
              {!!short_description && (
                <Grid item>
                  <div className={classes.creatorDescription}>{short_description}</div>
                </Grid>
              )}
              <Grid container item justifyContent='center'>
                <hr className={classes.hr} />
              </Grid>
            </Grid>
            {/* Question Inputs */}
            <QuestionInputWithAttachment
              label="What do you want to ask?"
              placeholder='Tap to type your question or ask by video.'
              errorMessage={touched.customer_message && errors.customer_message}
              handleFormImageChange={handleFormImageChange}
              handleFormRemoveAttachments={handleFormRemoveAttachments}
              handleFormAttachmentError={handleFormAttachmentError}
              imageUploadDisabled={imageUploadDisabled}
              onAttachmentUploading={(isUploading) => setAttachmentUploadIsLoading(isUploading)}
              values={values}
              fieldProps={getFieldProps('customer_message')}
              onBlur={handleTrimOnBlur}
              disabled={inPreviewMode}
            />
            <AppTextField 
              label="Your Name"
              placeholder='i.e. John Smith'
              errorMessage={touched.customer_name && errors.customer_name}
              fieldProps={{
                autoComplete: 'name',
                ...getFieldProps('customer_name')
              }}
              onBlur={handleTrimOnBlur}
              disabled={inPreviewMode}
              containerClass={classes.textFieldContainer}
            />
            <AppTextField 
              label="Your Email"
              placeholder="i.e. JohnSmith@gmail.com"
              errorMessage={touched.customer_email && errors.customer_email}
              fieldProps={{
                autoComplete: 'email',
                ...getFieldProps('customer_email')
              }}
              onBlur={handleTrimOnBlur}
              disabled={inPreviewMode}
            />
            <EmailCheckbox
              containerClass={classes.textFieldContainer}
              value={isEmailChecked}
              onChange={setIsEmailChecked}
              errorMessage={showEmailNotCheckedError && !isEmailChecked ? 'Email approval is required' : ''}
            />
            {/* price */}
            {amountOptionEnabled && <QuestionPriceCard formattedPrice={questionPrice} />}
            {tipOptionEnabled && <QuestionTipPriceCard displayName={display_name} />}
            {sponsorQnaEnabled && (
              <SponsoredCard 
                displayName={display_name}
                sponsorName={sponsor?.name}
                sponsorLogoUrl={sponsor?.logo_url}
                sponsorDescription={sponsor?.description}
                extraContainerClass={classes.sponsoredCard}
              />
            )}
            {isAway && <QuestionCreatorAwayCard displayName={display_name} />}
            {/* footer */}
            {inPreviewMode ? (
              <Grid item container justifyContent='space-between' className={classes.previewFooter}>
                <Grid item>
                  <div className={classes.link}>
                    AsqMe.com/<span className={classes.tag}>{asqMe_tag || '@asqMeTag'}</span>
                  </div>
                </Grid>
                <Grid item>
                  <CopyToClipboard
                    text={`${process.env.REACT_APP_WEB_BASE_URL}/${asqMe_tag || ''}`}
                    onCopy={() => {
                      alert.success(null, { title: "All set", body: "Link copied to clipboard.", timeout: 1000 + slideoutTransitionDelay })
                    }}
                  >
                    <ChainLinkIcon fillColor={blue} className={classes.chainlinkIcon} />
                  </CopyToClipboard>
                </Grid>
              </Grid>
            ) : (
              <Grid item container className={classes.customerFooter}>
                <Grid item xs={12}>
                  <Button
                    disabled={attachmentUploadIsLoading || saveIsLoading}
                    type='submit'
                    className={classes.saveBtn}
                    fullWidth
                    size='large'
                  >
                    {saveIsLoading ? (
                      <CircularProgress color='inherit' size={31} />
                    ) : amountOptionEnabled ? (
                      <div className={classes.saveBtnLabel}>
                        <span><AskQuestionIcon /></span>
                        <span>Preview Question</span>
                      </div>
                    ) : (
                      <div className={classes.saveBtnLabel}>
                        <span><PaperPlaneIconAlt fillColor='white' /></span>
                        <span>Send Question</span>
                      </div>
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12} className={classes.joinAsqContainer}>
                  <Button
                    className={classes.joinAsqBtn}
                    onClick={() => {
                      window.location.href = `${process.env.REACT_APP_WEB_BASE_URL}/?utm_source=qpage&utm_medium=qpage&utm_campaign=qpage1`;
                    }}
                  >
                    Join {creatorData.display_name} on AsqMe!
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ProfilePreview;
