import { useContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAlert } from 'react-alert';
import { Radio } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Fade from '@material-ui/core/Fade';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ProfilePreview from 'components/ProfilePreview';
import { MaskedInput } from 'components/MaskedInput';
import GridWrapperWithBanner from '../../components/GridWrapper/GridWrapperWithBanner';
import Select from 'react-select';
import { updateMessenger } from 'utils/intercomConfig';
import { convertCurrency, getCurrencySymbol } from 'utils/currency-utils';
import { getSupportedCountries, queryCreatorTypes } from 'store/creator';
import {
  AmountChargeIcon,
  ZeroChargeIcon,
  CircleErrorIcon,
  CircleSuccessIcon,
  ThankBankLogoNoBg,
  SponsoredQnAIcon,
  TrashcanIcon,
} from 'components/Icons';
import { AppDataContext } from 'Context';
import useUpload from 'hooks/upload';
import { checkIfTagExists, queryProfileTypes, saveProfile } from 'store/auth/profile';
import { pricePerQuestionOptions, initSupportedCountries, sponsorTrackingOptions } from './constants';
import { customValue, zeroValue, tipsValue, defaultAlertError } from 'utils/common/constants';
import chainlinkBlueBtn from 'assets/images/chainlink-blue.svg';
import infoIconImg from 'assets/images/info.svg';
import { errorStatuses } from 'utils/apiConfig';
import styles from './styles';
import PriceInfoDrawer from './PriceInfoDrawer';
import FeatureInfoDrawer from 'components/FeatureInfoDrawer';
import SaveProfileDrawer from './SaveProfileDrawer';
import { trackTypes, useTrackActions } from './utils';
import { slideoutTransitionDelay } from 'config/ui';
import ClipsElement from 'components/Clips/ClipsElement';
import { Helmet } from 'react-helmet';
import { redditPixelScript } from 'utils/reddit-pixel';
import { defaultLanguage, languagesLookup } from 'utils/lookups/supportedTranslationLanguages';
import AppTextField from 'components/Input/AppTextField';
import AppLabel from 'components/Input/AppLabel';
import AppButton from 'components/Buttons/AppButton';

const useStyles = makeStyles(styles);

const selectStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: '40.5px',
    fontFamily: 'Figtree-Regular',
    fontSize: '16px',
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
};

const Profile = () => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const classes = useStyles();
  const {
    common: { white },
    error: { dark: red },
    success: { light: green },
    blue,
    breakpoints,
  } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));
  const smDimensionView = useMediaQuery(breakpoints.down('sm'));

  const {
    appData: { tempProfileData, user, exchangeRates },
    setAppData,
  } = useContext(AppDataContext);

  const formRef = useRef({ valid: null, values: null, tempProfileData }); // ref used for temporary check and storing of profileData
  const checkTagTimeoutRef = useRef(null);
  const [previewProfileDrawer, setPreviewProfileDrawer] = useState(false);
  const [convertedPricePerQuestionOptions, setConvertedPricePerQuestionOptions] = useState(
    convertPricePerQuestionOptions(user.currency_code),
  );
  const [customPriceCurrencySymbol, setCustomPriceCurrencySymbol] = useState(
    getCurrencySymbol(user.currency_code ?? 'USD'),
  );
  const [supportedCountries, setSupportedCountries] = useState(initSupportedCountries);
  const [saveProfileDrawer, setSaveProfileDrawer] = useState(false);
  const [amountInfoDrawer, setAmountInfoDrawer] = useState(false);
  const [featureInfoDrawer, setFeatureInfoDrawer] = useState(null);

  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const drawerOpen = useMemo(
    () => saveProfileDrawer || amountInfoDrawer || !!featureInfoDrawer,
    [saveProfileDrawer, amountInfoDrawer, featureInfoDrawer],
  );

  // hide intercom launcher when a drawer is open
  useEffect(() => {
    if (drawerOpen) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [drawerOpen]);

  // track popup clicks and send metrics
  const onTrackAction = useTrackActions(user, exchangeRates);

  // asqMe tag field uniqueness call
  const {
    data: tagAlreadyExists,
    error: tagAlreadyExistsError,
    mutate: onCheckIfTagExists,
    reset: clearTagMutation,
  } = useMutation({
    mutationFn: checkIfTagExists,
    onSuccess: () => {
      checkTagTimeoutRef.current = null;
    },
    onError: (err) => {
      if (err.status !== errorStatuses.UNAUTHORIZED) {
        checkTagTimeoutRef.current = null;
        alert(null, { ...defaultAlertError, offsetError: true });
      }
    },
  });

  const { mutate: onSaveProfile, isLoading: saveProfileIsLoading } = useMutation({
    mutationFn: saveProfile,
    onSuccess: (_, data) => {
      queryClient.invalidateQueries({ queryKey: [queryProfileTypes.profile] });
      setSaveProfileDrawer(true);
      // alert.success(
      //   <div>
      //     <strong>Profile is successfully updated</strong>
      //   </div>,
      // );
    },
    onError: (err) => {
      if (err.status !== errorStatuses.UNAUTHORIZED) {
        alert.error(null, { ...defaultAlertError, offsetError: true });
      }
    },
  });

  // Delete from context any temporary stored profileData after mounting
  useEffect(() => {
    if (tempProfileData) setAppData((prev) => ({ ...prev, tempProfileData: null }));
  }, [tempProfileData]);

  const countriesOptions = supportedCountries
    .filter((x) => !x.noConnect)
    .map((c) => {
      return {
        label: `${c.country} - ${c.currency}`,
        value: `${c.country},${c.currency}`,
      };
    });

  const defaultPreferedLanguage = () => {
    const languageCode = user.translationSettings?.preferred_language || defaultLanguage;
    return languagesLookup.find((lang) => lang.value === languageCode);
  }

  const defaultSponsorTracking = () => {
    const analyticsType = user.sponsor?.analytics_type;
    return sponsorTrackingOptions.find((tracking) => tracking.value === analyticsType);
  }

  useQuery({
    queryKey: [queryCreatorTypes.countries],
    queryFn: getSupportedCountries,
    onSuccess: (resp) => {
      setSupportedCountries(resp);
    },
    onError: (err) => {
      if (err.status !== errorStatuses.UNAUTHORIZED)
        alert.error(null, { ...defaultAlertError, offsetError: true });
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    initialValues,
    dirty,
    isValid,
    values,
    errors,
    touched,
    setFieldTouched,
    resetForm,
    submitForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      banner_image: user.banner_image,
      banner_file: '',
      logo_image: user.logo_image,
      logo_file: '',
      display_name: user.display_name || '',
      country: {
        label: `${user.country} - ${user.currency_code}`,
        value: `${user.country},${user.currency_code}`,
      },
      short_description: user.short_description || '',
      price_per_question: user.price_per_question || '10',
      price_per_question_custom: user.price_per_question_custom || '',
      asqMe_tag: user.asqMe_tag || '',
      preferred_language: defaultPreferedLanguage(),
      youtube_tag: user.youtube_tag || '',
      patreon_tag: user.patreon_tag || '',
      sponsor_qna_enabled: user.sponsor_qna_enabled || false,
      sponsor_name: user.sponsor?.name || '',
      sponsor_logo_url: user.sponsor?.logo_url || '',
      sponsor_description: user.sponsor?.description || '',
      sponsor_cta_copy: user.sponsor?.cta_copy || '',
      sponsor_cta_url: user.sponsor?.cta_url || '',
      sponsor_analytics_type: defaultSponsorTracking(),
      sponsor_analytics_code: user.sponsor?.analytics_code || '',
      ...formRef.current.tempProfileData,
    },
    validationSchema: Yup.object({
      display_name: Yup.string().required('Display name is required'),
      short_description: Yup.string(),
      country: Yup.object({
        label: Yup.string(),
        value: Yup.string(),
      }).nullable(false),
      price_per_question: Yup.string().required(),
      price_per_question_custom: Yup.string().when('price_per_question', {
        is: (value) => value === customValue,
        then: (schema) => schema.required('Custom price input is required'),
      }),
      asqMe_tag: Yup.string()
        .required('Handle is required')
        .min(4, 'Handle too short')
        .max(16, 'Handle too big'),
      preferred_language: Yup.object({
        label: Yup.string(),
        value: Yup.string(),
      }).nullable(false),
      youtube_tag: Yup.string(),
      patreon_tag: Yup.string(),
      sponsor_description: Yup.string(),
      sponsor_name: Yup.string().when('sponsor_qna_enabled', {
        is: (value) => value === true,
        then: (schema) => schema.required('Sponsor name is required'),
      }),
      sponsor_logo_url: Yup.string().when('sponsor_qna_enabled', {
        is: (value) => value === true,
        then: (schema) => schema.required('Sponsor logo is required'),
      }),
      sponsor_cta_copy: Yup.string().when('sponsor_qna_enabled', {
        is: (value) => value === true && values.sponsor_cta_url?.length > 0,
        then: (schema) => schema.required('Button copy is required'),
      }),
      sponsor_cta_url: Yup.string().when('sponsor_qna_enabled', {
        is: (value) => value === true && values.sponsor_cta_copy?.length > 0,
        then: (schema) => schema.required('Button destination url is required'),
      }),
    }),
    onSubmit: (data, { setFieldValue }) => {
      if (
        tagAlreadyExists === true ||
        tagAlreadyExistsError ||
        !!checkTagTimeoutRef.current ||
        (typeof tagAlreadyExists !== 'boolean' && data.asqMe_tag !== user.asqMe_tag)
      ) {
        setAppData((prev) => ({ ...prev, tempProfileData: data }));
        return;
      }

      const {
        banner_file,
        banner_image,
        logo_file,
        logo_image,
        display_name,
        country,
        currency_code,
        preferred_language,
        short_description,
        ...dataToSend
      } = data;

      const truncatedDescription =
        short_description.length > 80 ? short_description.slice(0, 80) : short_description;

      const truncatedDisplayName =
        display_name.length > 30 ? display_name.slice(0, 30) : display_name;

      let truncatedSponsorName = null;
      if (data.sponsor_name) {
        truncatedSponsorName = data.sponsor_name.length > 20 ? data.sponsor_name.slice(0, 20) : data.sponsor_name;
      }

      let truncatedSponsorDescription = null;
      if (data.sponsor_description) {
        truncatedSponsorDescription = data.sponsor_description.length > 150 ? data.sponsor_description.slice(0, 150) : data.sponsor_description;
      }

      let truncatedSponsorCtaCopy = null;
      if (data.sponsor_cta_copy) {
        truncatedSponsorCtaCopy = data.sponsor_cta_copy.length > 45 ? data.sponsor_cta_copy.slice(0, 45) : data.sponsor_cta_copy;
      }

      const [countryName, currencyCode] = country.value.split(',');

      onSaveProfile(
        {
          ...dataToSend,
          display_name: truncatedDisplayName,
          short_description: truncatedDescription,
          banner_file,
          country: countryName,
          currency_code: currencyCode,
          banner_image: banner_file ? banner_image : undefined,
          logo_file,
          logo_image: logo_file ? logo_image : undefined,
          sponsor_qna_enabled: data.sponsor_qna_enabled,
          translationSettings: {
            preferred_language: preferred_language.value,
          },
          sponsor: {
            name: truncatedSponsorName,
            logo_url: data.sponsor_logo_url ? data.sponsor_logo_url : null,
            description: truncatedSponsorDescription,
            cta_copy: truncatedSponsorCtaCopy,
            cta_url: data.sponsor_cta_url ? data.sponsor_cta_url : null,
            analytics_type: data.sponsor_analytics_type?.value,
            analytics_code: data.sponsor_analytics_code ? data.sponsor_analytics_code : null,
          }
        },
        {
          onSuccess: () => {
            setFieldValue('display_name', truncatedDisplayName);
            setFieldValue('short_description', truncatedDescription);
            setFieldValue('sponsor_name', truncatedSponsorName ?? '');
            setFieldValue('sponsor_description', truncatedSponsorDescription ?? '');
            setFieldValue('sponsor_cta_copy', truncatedSponsorCtaCopy ?? '');
          },
        },
      );
    },
  });

  const showAsqMeTagCopiedAlert = useCallback(() => {
    alert.success(null, {
      title: 'All set',
      body: 'Link copied to clipboard.',
      timeout: 1000 + slideoutTransitionDelay,
      offsetError: true,
    });
  }, [alert])

  const asqMeTagUrl = useMemo(() => {
    return `${process.env.REACT_APP_WEB_BASE_URL}/${values.asqMe_tag || ''}`;
  }, [values.asqMe_tag]);

  const copyAsqMeTag = useCallback(() => {
    if (asqMeTagUrl != null &&
      values.asqMe_tag?.length >= 4 &&
      values.asqMe_tag?.length <= 16 &&
      !(tagAlreadyExists === true ||
          tagAlreadyExistsError ||
          !!checkTagTimeoutRef.current)) {
      navigator.clipboard.writeText(asqMeTagUrl);
    }
  }, [asqMeTagUrl, tagAlreadyExists, tagAlreadyExistsError, values.asqMe_tag?.length])


  // store every profileData change in ref
  useEffect(() => {
    formRef.current.valid = isValid;
    formRef.current.values = values;
  }, [isValid, values]);

  // while page unmounts, if form data are valid, try to submit and save them
  // else store them as tempProfileData in context to be available on next visit
  useEffect(() => {
    return () => {
      if (formRef.current.valid) submitForm();
      else setAppData((prev) => ({ ...prev, tempProfileData: formRef.current.values }));
    };
  }, []);

  const tag = useMemo(
    () =>
      touched.asqMe_tag &&
      !errors.asqMe_tag &&
      values.asqMe_tag &&
      values.asqMe_tag !== user.asqMe_tag
        ? values.asqMe_tag
        : null,
    [touched.asqMe_tag, errors.asqMe_tag, values.asqMe_tag, user],
  );

  useEffect(() => {
    clearTimeout(checkTagTimeoutRef.current);
    checkTagTimeoutRef.current = null;
    clearTagMutation();

    if (tag)
      checkTagTimeoutRef.current = setTimeout(() => {
        onCheckIfTagExists({ asqtag: tag });
      }, [500]);
  }, [tag]);

  const handleFormImageChange = useCallback(
    (formNames) => (formData) => {
      handleChange({
        target: {
          name: formNames.file,
          value: formData.encodedFile,
        },
      });
      handleChange({
        target: {
          name: formNames.image,
          value: formData.fileName,
        },
      });
    },
    [],
  );

  function convertPricePerQuestionOptions(currencyCode) {
    if (!currencyCode || currencyCode === 'USD') {
      return pricePerQuestionOptions;
    }

    let convertedOptions = [];

    for (const option of pricePerQuestionOptions) {
      const label = option.label;

      if (label === 'Custom') {
        convertedOptions.push(option);
        continue;
      }

      const value = Number(option.value);

      // Base rate will always be USD because pre defined price options are in USD. Therefore, we only convert the value for display purposes
      const convertedValue = Math.ceil(convertCurrency(value, 'USD', currencyCode, exchangeRates));
      const currencySymbol = getCurrencySymbol(currencyCode);
      const convertedLabel =
        currencySymbol.position === 'start'
          ? `${currencySymbol.symbol}${convertedValue}`
          : `${convertedValue} ${currencySymbol.symbol}`;

      convertedOptions.push({
        label: convertedLabel,
        value: `${value}`,
      });
    }

    return convertedOptions;
  }

  function convertCustomPricePerQuestion(currencyCode, oldCurrencyCode) {
    const customPrice = Number(values.price_per_question_custom);

    if (customPrice) {
      const convertFromCurrency = oldCurrencyCode ?? 'USD';
      const convertedCustomPrice = Math.ceil(
        convertCurrency(customPrice, convertFromCurrency, currencyCode, exchangeRates),
      );

      setFieldValue('price_per_question_custom', `${convertedCustomPrice}`);
    }
  }

  function handleCurrencyChange(newCurrencyCode, oldCurrencyCode) {
    setConvertedPricePerQuestionOptions(convertPricePerQuestionOptions(newCurrencyCode));
    convertCustomPricePerQuestion(newCurrencyCode, oldCurrencyCode);
    setCustomPriceCurrencySymbol(getCurrencySymbol(newCurrencyCode));
  }

  const {
    getRootProps: rootLogoProps,
    getInputProps: inputLogoProps,
    uploadIsLoading: logoUploadIsLoading,
  } = useUpload(handleFormImageChange({ image: 'logo_image', file: 'logo_file' }), {
    offsetError: true,
  });

  const {
    getRootProps: rootSponsorLogoProps,
    getInputProps: inputSponsorLogoProps,
    uploadIsLoading: sponsorLogoUploadIsLoading,
  } = useUpload((formData) => {
    handleChange({
      target: {
        name: 'sponsor_logo_url',
        value: formData.encodedFile,
      },
    });
  }, {
    offsetError: true,
    videoAccept: false,
  });

  const handleTrimOnBlur = useCallback(
    (e) => {
      e.target.value = e.target.value.trim();
      handleChange(e);
      handleBlur(e);
    },
    [handleChange, handleBlur],
  );

  // unmount cleanup
  useEffect(
    () => () => {
      queryClient.invalidateQueries({ queryKey: [queryCreatorTypes.countries] });
    },
    [],
  );

  const [country, currencyCode] = values.country.value.split(',');

  // at initial profile visit, when display name is typed and blured
  // it's value is transformed and copied to asqme tag field
  const initializeTagFromDisplayName = useCallback(
    (e) => {
      handleTrimOnBlur(e);
      if (user.first_time_submission || !!user.asqMe_tag) return;

      if (!e.target.value) return;

      const asqmeTagField = {
        target: { name: 'asqMe_tag', value: `@${e.target.value.replace(' ', '').slice(0, 30)}` },
      };

      handleChange(asqmeTagField);
      setFieldTouched('asqMe_tag', true);
    },
    [handleTrimOnBlur, user.first_time_submission, user.asqMe_tag, user.display_name],
  );

  const sponsoredQnASelected = values.sponsor_qna_enabled;
  const zeroValueSelected = values.price_per_question === zeroValue && !sponsoredQnASelected;
  const tipValueSelected = values.price_per_question === tipsValue && !sponsoredQnASelected;
  const amountValueSelected =
    !!values.price_per_question && ![zeroValue, tipsValue].includes(values.price_per_question) && !sponsoredQnASelected;;

  return (
    <>
      <Helmet>
        <script>{redditPixelScript}</script>
      </Helmet>
      <Grid container className={classes.homeContainer}>
        <GridWrapperWithBanner
          bannerImageUrl={values.logo_file || values.logo_image}
          bannerShowLogo={true}
          rootLogoProps={rootLogoProps()}
          inputLogoProps={inputLogoProps()}
          isPhotoUploadLoading={logoUploadIsLoading}
        >
          {saveProfileIsLoading && (
            <Backdrop open className={classes.backdrop}>
              <CircularProgress color='inherit' thickness={3} size={60} />
            </Backdrop>
          )}
          {/* form */}
          <form
            onSubmit={(e) => {
              setAttemptedSubmit(true);
              handleSubmit(e);
              copyAsqMeTag();
            }}
            className={classes.form}
          >
            <Grid
              container
              direction='column'
              justifyContent='space-between'
              className={classes.homeInnerContainer}
            >
              {/* we need this for correct grid layout */}
              <Grid
                item
                container
                direction='column'
                className={classes.dataInputSection}
              >
                {/* display name */}
                <ProfileSection>
                  <AppTextField
                    label='Display Name'
                    placeholder='i.e. John Smith'
                    fieldProps={getFieldProps('display_name')}
                    isRequired
                    onBlur={initializeTagFromDisplayName}
                    errorMessage={
                      touched.display_name &&
                      (errors.display_name ||
                        (values.display_name.length > 30
                          ? `Display name can only be 30 characters. You’re ${
                              values.display_name.length - 30
                            } over.`
                          : null))
                    }
                    error={
                      !!touched.display_name &&
                      (!!errors.display_name || values.display_name.length > 30)
                    }
                  />
                </ProfileSection>
                {/* short description */}
                <ProfileSection>
                  <AppTextField
                    label='Short Description'
                    placeholder='i.e. Ask me about DIY projects!'
                    fieldProps={getFieldProps('short_description')}
                    onBlur={handleTrimOnBlur}
                    multiline
                    errorMessage={
                      touched.short_description &&
                      values.short_description.length > 80 &&
                      `Short description can only be 80 characters. You’re ${
                        values.short_description.length - 80
                      } over.`
                    }
                    error={
                      !!touched.short_description && values.short_description.length > 80
                    }
                  />
                </ProfileSection>
                {/* asqMe link */}
                <ProfileSection>
                  <Grid container direction='column'>
                    <AppLabel label='AsqMe Handle' isRequired />
                    <Grid 
                      item 
                      container 
                      alignItems='center' 
                      className={classes.asqMeHandleInputContainer}
                      wrap='nowrap'
                      style={{ marginBottom: (!!touched.asqMe_tag && !!errors.asqMe_tag) || typeof tagAlreadyExists === 'boolean' ? 10 : 0 }}
                    >
                      <Grid item>
                        <div className={classes.prefixLabel}>AsqMe.com/</div>
                      </Grid>
                      <Grid item style={{ flex: 1 }}>
                        <AppTextField
                          placeholder='@YourHandleHere*'
                          fieldProps={getFieldProps('asqMe_tag')}
                          onBlur={handleTrimOnBlur}
                          inputComponent={MaskedInput}
                          inputProps={{
                            mask: '@###############',
                            definitions: {
                              '#': /[A-Za-z0-9]/,
                            },
                          }}
                          errorMessage={
                            touched.asqMe_tag && errors.asqMe_tag ? (
                              <span className={classes.tagHelperText}>{errors.asqMe_tag}</span>
                            ) : typeof tagAlreadyExists === 'boolean' ? (
                              tagAlreadyExists ? (
                                <span className={classes.tagHelperText}>
                                  <CircleErrorIcon
                                    width={18}
                                    height={18}
                                    fillColor={red}
                                    strokeColor={white}
                                  />
                                  <span>Handle not available</span>
                                </span>
                              ) : (
                                <span className={`${classes.tagHelperText} success`}>
                                  <CircleSuccessIcon
                                    width={18}
                                    height={18}
                                    fillColor={green}
                                    strokeColor={white}
                                  />
                                  <span>Handle available</span>
                                </span>
                              )
                            ) : !!tagAlreadyExistsError ? (
                              <span className={classes.tagHelperText}>Service Error</span>
                            ) : null
                          }
                          error={!!touched.asqMe_tag && !!errors.asqMe_tag}
                          zeroHeightError
                        />
                      </Grid>
                      {((initialValues.asqMe_tag === values.asqMe_tag && !!values.asqMe_tag) ||
                        tagAlreadyExists === false) && (
                        <Grid item>
                          <Fade in>
                            <div>
                              <CopyToClipboard
                                text={asqMeTagUrl}
                                onCopy={showAsqMeTagCopiedAlert}
                              >
                                <img
                                  src={chainlinkBlueBtn}
                                  className={classes.chainIcon}
                                  alt=''
                                />
                              </CopyToClipboard>
                            </div>
                          </Fade>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </ProfileSection>
                {/* Preferred language */}
                <ProfileSection extraClassName={classes.sectionSpacing}>
                  <AppLabel label='Preferred Language' />
                  <Select
                    className={classes.select}
                    styles={selectStyles}
                    classNamePrefix='select'
                    value={values.preferred_language}
                    isClearable={false}
                    isMulti={false}
                    isSearchable
                    options={languagesLookup}
                    {...getFieldProps('preferred_language')}
                    onChange={(option) => {
                      handleChange({
                        target: { name: 'preferred_language', value: option },
                      });
                    }}
                  />
                </ProfileSection>
                {/* price options section */}
                <ProfileSection extraClassName={classes.sectionSpacing}>
                  <Grid container direction='column'>
                    <AppLabel 
                      label='How do you want to charge?' 
                      isRequired
                      extraRightComponent={
                        <Grid
                          item
                          className={classes.infoImgContainer}
                          onClick={() => {
                            onTrackAction(trackTypes.tooltip);
                            setAmountInfoDrawer(true);
                          }}
                        >
                          <img className={classes.infoIconImg} src={infoIconImg} alt='' />
                        </Grid>
                      }
                    />
                    {/* price options */}
                    <Grid
                      item
                      container
                      justifyContent='space-between'
                      spacing={2}
                    >
                      {/* Sponsor */}
                      <Grid item className={classes.priceOptionOuterContainer}>
                        <Grid
                          container
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          className={`${classes.priceOptionContainer} ${
                            sponsoredQnASelected ? 'selected' : ''
                          }`}
                          onClick={() => {
                            if (user.is_subscribed_and_profile_completed) {
                              handleChange({
                                target: { name: 'sponsor_qna_enabled', value: true },
                              });
                              handleChange({
                                target: { name: 'price_per_question', value: user.tip_jar_enabled ? tipsValue : zeroValue },
                              });
                            } else {
                              setFeatureInfoDrawer('free');
                            }
                          }}
                        >
                          <Grid item>
                            <SponsoredQnAIcon fillColor={sponsoredQnASelected ? blue : undefined} />
                          </Grid>
                          <Grid item>
                            <div className={classes.priceOptionText} style={{ color: sponsoredQnASelected ? blue : undefined }}>
                              Sponsored <br />
                              Q&A
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* $0 */}
                      <Grid item className={classes.priceOptionOuterContainer}>
                        <Grid
                          container
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          className={`${classes.priceOptionContainer} ${
                            zeroValueSelected ? 'selected' : ''
                          }`}
                          onClick={() => {
                            if (user.is_subscribed_and_profile_completed) {
                              handleChange({
                                target: { name: 'price_per_question', value: zeroValue },
                              });
                              handleChange({
                                target: { name: 'sponsor_qna_enabled', value: false },
                              });
                            } else {
                              onTrackAction(trackTypes.free);
                              setFeatureInfoDrawer('free');
                            }
                          }}
                        >
                          <Grid item>
                            <ZeroChargeIcon fillColor={zeroValueSelected ? blue : undefined} height={39} />
                          </Grid>
                          <Grid item>
                            <div className={classes.priceOptionText} style={{ color: zeroValueSelected ? blue : undefined }}>
                              Don't <br />
                              Charge
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* tip */}
                      <Grid item className={classes.priceOptionOuterContainer}>
                        <Grid
                          container
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          className={`${classes.priceOptionContainer} ${
                            tipValueSelected ? 'selected' : ''
                          }`}
                          onClick={() => {
                            handleChange({
                              target: { name: 'price_per_question', value: tipsValue },
                            });
                            handleChange({
                              target: { name: 'sponsor_qna_enabled', value: false },
                            });
                          }}
                        >
                          <Grid item>
                            <ThankBankLogoNoBg fillColor={tipValueSelected ? blue : '#898a8d' } height={39} />
                          </Grid>
                          <Grid item>
                            <div className={classes.priceOptionText} style={{ color: tipValueSelected ? blue : undefined }}>
                              Tips <br />
                              Only
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* amount */}
                      <Grid item className={classes.priceOptionOuterContainer}>
                        <Grid
                          container
                          direction='column'
                          justifyContent='center'
                          alignItems='center'
                          className={`${classes.priceOptionContainer} ${
                            amountValueSelected ? 'selected' : ''
                          }`}
                          onClick={() => {
                            handleChange({
                              target: { name: 'price_per_question', value: '10' },
                            })
                            handleChange({
                              target: { name: 'sponsor_qna_enabled', value: false },
                            });
                          }}
                        >
                          <Grid item>
                            <AmountChargeIcon
                              fillColor={amountValueSelected ? blue : undefined}
                              height={39}
                            />
                          </Grid>
                          <Grid item>
                            <div className={classes.priceOptionText} style={{ color: amountValueSelected ? blue : undefined }}>
                              Set <br />
                              Price
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {touched.price_per_question && errors.price_per_question && (
                      <Grid item>
                        <div className={classes.radioGroupErrorText}>An option is required</div>
                      </Grid>
                    )}
                  </Grid>
                </ProfileSection>
                {/* price amount radio group */}
                <ProfileSection isVisible={amountValueSelected}>
                  <Collapse in={amountValueSelected}>
                    {/* price radiogroup */}
                    <Grid container direction='column'>
                      <AppLabel label={`Price per Question (${currencyCode})`} isRequired />
                      <Grid item container direction='column'>
                        <Grid item>
                          <Radio.Group
                            size='large'
                            className={classes.radioGroup}
                            options={convertedPricePerQuestionOptions}
                            optionType='button'
                            {...getFieldProps('price_per_question')}
                            onChange={(e) => {
                              handleChange(e);

                              if (
                                e.target.value !== customValue &&
                                !!values.price_per_question_custom
                              ) {
                                handleChange({
                                  target: { name: 'price_per_question_custom', value: '' },
                                });
                              }
                            }}
                          />
                          {touched.price_per_question && errors.price_per_question && (
                            <div className={classes.radioGroupErrorText}>
                              Price per Question is required
                            </div>
                          )}
                        </Grid>
                      </Grid>
                      {/* custom price input */}
                      <Grid item container direction='column' className={classes.spacing}>
                        <Collapse in={values.price_per_question === customValue}>
                          <AppLabel label='Custom Price Input' isRequired />
                          <Grid item>
                            <AppTextField
                              placeholder='i.e. 25'
                              fieldProps={getFieldProps('price_per_question_custom')}
                              onBlur={handleTrimOnBlur}
                              inputComponent={MaskedInput}
                              inputProps={{
                                mask: '#0000000000',
                                definitions: {
                                  '#': /[1-9]/,
                                },
                              }}
                              startAdornment={
                                customPriceCurrencySymbol.position ===
                                  'start' && (
                                  <InputAdornment position='end'>
                                    <div className={classes.dollarSign}>
                                      {customPriceCurrencySymbol.symbol}
                                    </div>
                                  </InputAdornment>
                                )
                              }
                              endAdornment={
                                customPriceCurrencySymbol.position === 'end' && (
                                  <InputAdornment position='start'>
                                    <div className={classes.dollarSign}>
                                      {customPriceCurrencySymbol.symbol}
                                    </div>
                                  </InputAdornment>
                                )
                              }
                              errorMessage={
                                touched.price_per_question_custom &&
                                errors.price_per_question_custom
                              }
                              error={
                                !!touched.price_per_question_custom &&
                                !!errors.price_per_question_custom
                              }
                            />
                          </Grid>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Collapse>
                </ProfileSection>
                {/* Sponsor Details */}
                <ProfileSection isVisible={sponsoredQnASelected}>
                  <Collapse in={sponsoredQnASelected}>
                    <Grid container direction='column'>
                      <Grid item>
                        <AppTextField
                          label='Sponsor'
                          placeholder='Enter the sponsor’s name. 20 character maximum.'
                          fieldProps={getFieldProps('sponsor_name')}
                          onBlur={handleTrimOnBlur}
                          isRequired
                          errorMessage={
                            touched.sponsor_name &&
                            (errors.sponsor_name ||
                              (values.sponsor_name.length > 20
                                ? `Sponsor name can only be 20 characters. You’re ${
                                    values.sponsor_name.length - 20
                                  } over.`
                                : null))
                          }
                          error={
                            !!touched.sponsor_name &&
                            (!!errors.sponsor_name || values.sponsor_name.length > 20)
                          }
                        />
                      </Grid>
                      {/* Sponsor Logo */}
                      <Grid 
                        container 
                        item
                        direction={smDimensionView && !values.sponsor_logo_url ? 'column' : 'row'}
                        alignItems={smDimensionView && !values.sponsor_logo_url ? undefined : 'flex-end'}
                        spacing={1}
                        className={classes.spacing}
                      >
                        <Grid container item direction={smDimensionView ? 'column' : 'row'} wrap='nowrap' justifyContent='space-between' style={{ flex: 1 }}>
                          <Grid item>
                            <AppLabel
                              label='Sponsor Logo'
                              subLabel='Add a sponsor logo to your question page, answer page, and audience email.'
                              isRequired
                            />
                          </Grid>
                          {values.sponsor_logo_url && (
                            <Grid item container justifyContent={smDimensionView ? 'center' : 'flex-start'} style={{ flex: 0 }}>
                              <Grid item>
                                <img src={values.sponsor_logo_url} alt='sponsor logo' className={classes.sponsorLogo} />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                        {values.sponsor_logo_url ? (
                          <Grid item>
                            <TrashcanIcon 
                              fill="#9e9e9e" 
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleChange({
                                  target: { name: 'sponsor_logo_url', value: '' },
                                });
                              }} 
                            />
                          </Grid>
                        ) : (
                        <Grid item {...rootSponsorLogoProps()}>
                          <AppButton 
                            fullWidthBreakpoint='sm' 
                            disabled={sponsorLogoUploadIsLoading}
                          >
                            Upload Logo
                          </AppButton>
                          <input type='hidden' {...getFieldProps('sponsor_logo_url')} {...inputSponsorLogoProps()} />
                        </Grid>

                        )}
                      </Grid>
                      {!!errors.sponsor_logo_url && attemptedSubmit && (
                        <Grid item xs={12} className={classes.inputError}>
                          <div>{errors.sponsor_logo_url}</div>
                        </Grid>
                      )}
                      <Grid item className={classes.spacing}>
                        <AppTextField
                          label='Descriptive Copy'
                          placeholder='Add a short tagline, slogan, or other sponsorship copy to appear near the logo. 150 character maximum.'
                          multiline
                          fieldProps={getFieldProps('sponsor_description')}
                          onBlur={handleTrimOnBlur}
                          errorMessage={
                            touched.sponsor_description &&
                            values.sponsor_description.length > 150 &&
                            `Descriptive copy can only be 150 characters. You’re ${
                              values.sponsor_description.length - 150
                            } over.`
                          }
                          error={
                            !!touched.sponsor_description && values.sponsor_description.length > 150
                          }
                        />
                      </Grid>
                      <Grid container item direction='column' className={classes.spacing}>
                        <Grid item>
                          <AppLabel
                            label='Sponsor’s Call to Action'
                            subLabel='Add a custom button on the answer page to buy, learn more, sign up, etc.'
                          />
                        </Grid>
                        <Grid 
                          container
                          item
                          alignItems='center'
                          className={classes.subSpacing}
                        >
                          <Grid item className={classes.sponsorCtaLabelContainer}>
                            <div className={classes.prefixLabel}>Button</div>
                          </Grid>
                          <Grid item style={{ flex: 1 }}>
                            <AppTextField
                              placeholder='Enter the button copy. 45 character maximum.'
                              fieldProps={getFieldProps('sponsor_cta_copy')}
                              onBlur={handleTrimOnBlur}
                              errorMessage={
                                touched.sponsor_cta_copy &&
                                (errors.sponsor_cta_copy ||
                                  (values.sponsor_cta_copy.length > 45
                                    ? `Sponsor button copy can only be 45 characters. You’re ${
                                        values.sponsor_cta_copy.length - 45
                                      } over.`
                                    : null))
                              }
                              error={
                                !!touched.sponsor_cta_copy &&
                                (!!errors.sponsor_cta_copy || values.sponsor_cta_copy.length > 45)
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid 
                          container
                          item
                          alignItems='center'
                          className={classes.subSpacing}
                        >
                          <Grid item className={classes.sponsorCtaLabelContainer}>
                            <div className={classes.prefixLabel}>URL</div>
                          </Grid>
                          <Grid item>
                            <div className={`${classes.prefixLabel} ctaUrl`}>https://</div>
                          </Grid>
                          <Grid item style={{ flex: 1 }}>
                            <AppTextField
                              placeholder='Enter the destination URL under the button.'
                              fieldProps={getFieldProps('sponsor_cta_url')}
                              onBlur={handleTrimOnBlur}
                              errorMessage={
                                touched.sponsor_cta_url &&
                                errors.sponsor_cta_url
                              }
                              error={
                                !!touched.sponsor_cta_url &&
                                !!errors.sponsor_cta_url
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container item direction='column' className={classes.spacing}>
                        <Grid item>
                          <AppLabel
                            label='Analytics'
                            subLabel='Add your sponsor’s tracking pixel to your question page and answer page.'
                          />
                        </Grid>
                        <Grid container item direction='row' spacing={1} className={classes.subSpacing}>
                          <Grid item>
                            <Select
                              className={classes.select}
                              styles={selectStyles}
                              classNamePrefix='select'
                              value={values.sponsor_analytics_type}
                              placeholder='Select tracking'
                              isClearable={false}
                              isMulti={false}
                              isSearchable
                              options={sponsorTrackingOptions}
                              {...getFieldProps('sponsor_analytics_type')}
                              onChange={(option) => {
                                handleChange({
                                  target: { name: 'sponsor_analytics_type', value: option },
                                });
                              }}
                            />
                          </Grid>
                          <Grid item style={{ flex: 1 }}>
                            <AppTextField
                              placeholder='Enter tracking code.'
                              fieldProps={getFieldProps('sponsor_analytics_code')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Collapse>
                </ProfileSection>
                {/* country drop down */}
                {!user.connected_account_enabled &&
                  !values.sponsor_qna_enabled &&
                  values.price_per_question !== zeroValue &&
                  values.price_per_question !== tipsValue &&
                  process.env.REACT_APP_ENABLE_COUNTRY_DROPDOWN === 'true' && (
                    <ProfileSection>
                      <Grid container direction='column'>
                        <AppLabel label='Select Country' isRequired />
                        {/* drop down */}
                        <Grid item>
                          <Select
                            className={classes.select}
                            styles={selectStyles}
                            classNamePrefix='select'
                            fullWidth
                            menuPlacement='top'
                            value={values.country}
                            isClearable={false}
                            placeholder='i.e. United States - USD'
                            {...getFieldProps('country')}
                            onChange={(option) => {
                              const newCurrencyCode = option.value.split(',')[1];
                              const oldCurrencyCode = values.country.value.split(',')[1];

                              handleCurrencyChange(newCurrencyCode, oldCurrencyCode);

                              handleChange({
                                target: { name: 'country', value: option },
                              });
                            }}
                            options={countriesOptions}
                            error={!!touched.country && !values.country}
                          />
                        </Grid>
                        <Grid item>
                          <FormHelperText className={classes.errorText}>
                            {!!touched.country ? errors.country : ''}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </ProfileSection>
                  )
                }
                {/* Clips youtube connect */}
                {process.env.REACT_APP_ENABLE_CLIPS === 'true' && (
                  <ProfileSection extraClassName={classes.sectionSpacing}>
                    <AppLabel 
                      label={user.clips_enabled
                        ? 'YouTube Channel (connected)'
                        : 'Connect YouTube Channel'} 
                    />
                    <ClipsElement showEnabledClips />
                  </ProfileSection>
                )}
                {/* footer - buttons section */}
                <ProfileSection>
                  <Grid 
                    container 
                    direction={mobileView ? 'column' : 'row'}
                    spacing={1} 
                    justifyContent='flex-end'
                    className={classes.buttonContainer}
                  >
                    <Grid item>
                      <AppButton
                        onClick={() => setPreviewProfileDrawer(true)}
                        variant='secondary'
                        disabled={logoUploadIsLoading || sponsorLogoUploadIsLoading}
                        fixedWidth={smDimensionView ? undefined : 164}
                        fullWidthBreakpoint='sm' 
                      >
                        Preview Profile
                      </AppButton>
                    </Grid>
                    <Grid item>
                      <AppButton
                        disabled={logoUploadIsLoading || saveProfileIsLoading || sponsorLogoUploadIsLoading}
                        type='submit'
                        fixedWidth={smDimensionView ? undefined : 164}
                        fullWidthBreakpoint='sm' 
                      >
                        Save Profile
                      </AppButton>
                    </Grid>
                  </Grid>
                </ProfileSection>
              </Grid>
            </Grid>
          </form>
        </GridWrapperWithBanner>
      </Grid>
      {/* informational popups section */}
      <PriceInfoDrawer
        open={amountInfoDrawer}
        mobileType={mobileView}
        onClose={() => setAmountInfoDrawer(false)}
        onLinkSelect={() => {
          setFeatureInfoDrawer('free');
          setAmountInfoDrawer(false);
        }}
      />
      <FeatureInfoDrawer
        open={!!featureInfoDrawer}
        type={featureInfoDrawer}
        mobileType={mobileView}
        onClose={() => setFeatureInfoDrawer(null)}
      />
      <SaveProfileDrawer
        asqMeTag={user.asqMe_tag}
        open={saveProfileDrawer}
        mobileType={mobileView}
        onClose={() => setSaveProfileDrawer(false)}
        partner={user.partner_integration}
      />
      {/* profile preview drawer */}
      <Drawer
        classes={{ root: classes.drawer, paperAnchorTop: classes.paper }}
        transitionDuration={0}
        anchor='top'
        open={previewProfileDrawer}
        onClose={() => setPreviewProfileDrawer(false)}
      >
        <ProfilePreview
          inPreviewMode
          imageUploadDisabled
          amountOptionEnabled={![tipsValue, zeroValue].includes(values.price_per_question)}
          tipOptionEnabled={values.price_per_question === tipsValue && !values.sponsor_qna_enabled}
          sponsorQnaEnabled={values.sponsor_qna_enabled}
          onPreviewClose={() => setPreviewProfileDrawer(false)}
          creatorData={{ 
            ...values,
            currency_code: values.country?.value?.split(',')[1] ?? 'USD',
            sponsor: {
              name: values.sponsor_name,
              logo_url: values.sponsor_logo_url,
              description: values.sponsor_description,
              cta_copy: values.sponsor_cta_copy,
              cta_url: values.sponsor_cta_url,
            }
          }}
        />
      </Drawer>
    </>
  );
};

const ProfileSection = (props) => {
  const { children, extraClassName = '', isVisible = true } = props;

  const classes = useStyles();

  return (
    <Grid item container className={`${isVisible ? classes.spacing : ''} ${extraClassName}`}>
      <Grid item md sm xs />
      <Grid item md={12} xs={10}>
        {children}
      </Grid>
      <Grid item md sm xs />
    </Grid>
  )
}

export default Profile;
