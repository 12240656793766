import { useState, Fragment, useCallback, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from 'react-alert';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import AsqMeCreatorHeader from 'components/Header/AsqMeCreatorHeader';
import SubmitSuccess from 'components/SubmitSuccess';
import { CloseIcon, SpeechBubbleIcon, TranslateIcon } from 'components/Icons';
import QuestionField from './QuestionField';
import FeedbackField from './FeedbackField';
import { sendFollowUpQuestion, sendFeedback, acceptAnswer } from 'store/customer';
import { capturePaymentAuthorization } from 'store/payment';
import downArrowImg from 'assets/images/pointer-down.svg';
import rightArrowImg from 'assets/images/pointer-right.svg';
import successImg from 'assets/images/success.svg';
import errorImg from 'assets/images/error.png';
import verifiedBadge from 'assets/images/verified-badge.png';
import translateImg from 'assets/images/translate.svg';
import lateAnswerImg from 'assets/images/late-clock.svg';
import { defaultAlertError, tipsValue } from 'utils/common/constants';
import styles from './styles';
import AsqButton from 'routes/Subscribe/asq-button';
import { sendTrackCallTB7 } from 'store/customer';
import { TimeSpan } from 'utils/time-span';
import { ClipsPlayButton } from 'components/Clips/ClipsButtons';
import { ClipsVideoPlayerDialog } from 'components/Clips/ClipsVideoPlayerDialog';
import { SponsoredCard } from 'components/Sponsor/SponsorCard';
import { GoogleTagManager, MetaPixel } from 'components/ProfilePreview/sponsor-analytics';
import AppButton from 'components/Buttons/AppButton';

const useStyles = makeStyles(styles);

const allowedFollowUps = parseInt(process.env.REACT_APP_AVAILABLE_FOLLOWUPS);

const generateCollapsedRows = (questions) =>
  questions.reduce((obj, q, index) => {
    obj[q.id] = false;

    if (q.answer) {
      obj[q.answer.id] = index === questions.length - 1
    }

    return obj;
  }, {});

const generateTranslatedRows = (questions) =>
  questions.reduce((obj, q) => {
    if (q.answer) obj[q.answer.id] = false;
    return obj;
  }, {});

const AnswerFlow = ({
  answerData,
  followUpSelected,
  customerPaymentMethod,
  onSetStatus,
  onAnswerAcceptanceSuccess,
  onResetStatus,
  skipPayment,
}) => {
  const alert = useAlert();
  const { breakpoints } = useTheme();
  const classes = useStyles();

  const mobileTabletView = useMediaQuery(breakpoints.down('sm'));

  const navigate = useNavigate();

  const [verifiedAnswerModalOpen, setVerifiedAnswerModalOpen] = useState(false);
  const [questionCollapsed, setQuestionCollapsed] = useState(
    generateCollapsedRows(answerData.questions),
  );
  const [translatedToggledAnswer, setTranslatedToggledAnswer] = useState(
    generateTranslatedRows(answerData.questions),
  );
  const [clipsVideo, setClipsVideo] = useState(null);

  const handleChageQuestionCollapsed = useCallback((id) => {
    setQuestionCollapsed((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const handleChangeTranslatedToogledAnswer = useCallback((id) => {
    setTranslatedToggledAnswer((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const { mutate: onCapturePaymentAuthorization, isLoading: paymentIsloading } = useMutation({
    mutationFn: capturePaymentAuthorization,
    onSuccess: () => {
      onAnswerAcceptanceSuccess(answerData.price, answerData.currency_code, false);
    },
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const isThankBankCompleted = useMemo(() => {
    const thankBankSession = answerData.thankBankSession;
    const areResponsesSubmitted = thankBankSession.is_comment_submitted && thankBankSession.is_rating_submitted;
    const isTipJarEnabled = answerData.creator.tip_jar_enabled;
    const isTipSubmitted = thankBankSession.is_tip_paid || (thankBankSession.tip_payment_method != null && thankBankSession.tip_payment_method !== 'CARD');

    return areResponsesSubmitted && (!isTipJarEnabled || isTipSubmitted);
  }, [answerData]);

  const onNoButton = () => {
    const code = answerData.thankBankSession.thank_bank_session_tracking_code

    if (areAnyFollowUpsAvailable) {
      sendTrackCallTB7({ code, data: {
        url: window.location.href,
        page_name: 'Answer',
        cta_verbiage: 'No (ask a follow-up)',
        destination_URL: window.location.href,
        destination_page_name: 'Answer'
      }})
    } else {
      sendTrackCallTB7({ code, data: {
        url: window.location.href,
        page_name: 'Answer',
        cta_verbiage: 'No (give feedback)',
        destination_URL: window.location.href,
        destination_page_name: 'Answer'
      }})
    }

    onSetStatus();
  }

  const onYesButton = () => {
    const code = answerData.thankBankSession.thank_bank_session_tracking_code

    sendTrackCallTB7({ code, data: {
      url: window.location.href,
      page_name: 'Answer',
      cta_verbiage: 'Yes (accept)',
      destination_URL: `${window.location.origin}/thx/${code}`,
      destination_page_name: 'ThankBank'
    }})

    acceptAnswerHandler({ session_tracking_code });
  }

  const { mutate: onAcceptAnswer, isLoading: acceptAnswerIsLoading } = useMutation({
    mutationFn: acceptAnswer,
    onSuccess: () => {
      onAnswerAcceptanceSuccess(null, null, false);
    },
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const {
    mutate: onSendFollowUpQuestion,
    data: followUpSubmissionSuccess,
    isLoading: followUpSubmissionIsLoading,
  } = useMutation({
    mutationFn: sendFollowUpQuestion,
    onError: (err) => {
      alert.error(null, defaultAlertError)
    },
  });

  const {
    mutate: onSendFeedback,
    data: feedbackSubmissionSuccess,
    isLoading: feedbackSubmissionIsLoading,
  } = useMutation({
    mutationFn: sendFeedback,
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  const {
    answer_verified,
    creator: {
      banner_image,
      logo_image,
      display_name,
    } = {},
    creator_asqMe_tag,
    feedback,
    followUps,
    isCompleted,
    price,
    currency_code,
    questions = [],
    questionDowngradedToStandard,
    session_tracking_code,
    is_sponsor_qna,
    sponsor_name,
    sponsor_cta_copy,
    sponsor_cta_url,
    sponsor_description,
    sponsor_logo_url,
    sponsor_analytics_type,
    sponsor_analytics_code,
  } = answerData || {};

  const waitingForCreatorsFollowUpAnswer = useMemo(
    () => questions.some((q) => !q.answer),
    [questions],
  );

  const isPriorityFlow = customerPaymentMethod === 'PRIORITY';
  const acceptAnswerHandler = skipPayment ? onAcceptAnswer : onCapturePaymentAuthorization;

  const areAnyFollowUpsAvailable = followUps < allowedFollowUps;

  // in $0 flow (skipPayment) && in Tip flow with answer acceptance only (price = tipValue)
  // there is no payment, so hide payment text after completion visit
  const hidePaymentTextInCompletedStatus = skipPayment || price === tipsValue;

  const showClipsVideo = (session) => {
    let timestamp = null;

    if (session.clips_timestamp) {
      timestamp = new TimeSpan(session.clips_timestamp);
    }

    const video = {
      id: session.clipsCreatorItem?.external_id,
      title: session.clipsCreatorItem?.title,
      timestamp
    }

    setClipsVideo(video)
  };

  return !!followUpSubmissionSuccess || !!feedbackSubmissionSuccess ? (
    <SubmitSuccess
      creatorData={{ 
        ...answerData.creator, 
        creatorAsqMeTag: creator_asqMe_tag,
        sponsor_qna_enabled: is_sponsor_qna,
        sponsor: {
          name: sponsor_name,
          logo_url: sponsor_logo_url,
          description: sponsor_description,
          cta_copy: sponsor_cta_copy,
          cta_url: sponsor_cta_url,
        }
      }}
      isFollowUpSubmitted={!!followUpSubmissionSuccess}
      isFeedBackSubmitted={!!feedbackSubmissionSuccess}
    />
  ) : (
    <Grid container className={classes.mainContainer}>
      {is_sponsor_qna && (
        <>
          {sponsor_analytics_type === 'facebook_pixel' && <MetaPixel pixelId={sponsor_analytics_code} />}
          {sponsor_analytics_type === 'google_analytics' && <GoogleTagManager gtmId={sponsor_analytics_code} />}
        </>
      )}
      <Grid item xs={12}>
        <AsqMeCreatorHeader
          title={display_name}
          iconUrl={logo_image}
          showLogo={true}
        />
      </Grid>
      <Grid container className={classes.revealAnswerContainer}>
        <Grid item sm={3} xs />
        <Grid item sm={6} xs={12}>
          <Grid
            container
            direction='column'
            justifyContent='space-between'
            className={classes.revealAnswerInnerContainer}
          >
            {/* question/answer display */}
            <Grid item container direction='column' className={classes.qnaContainer}>
              {questions.map((questionSet, idx) => {
                const { answer, id } = questionSet;
                const isFollowUp = idx > 0;

                return (
                  <Fragment key={idx}>
                    {/* hr */}
                    {isFollowUp && (
                      <Grid item container direction='column' spacing={2} className={classes.spacing}>
                        <Grid item container>
                          <Grid item xs />
                          <Grid item xs={10}>
                            <hr className={classes.hr} />
                          </Grid>
                          <Grid item xs />
                        </Grid>
                        <Grid item>
                          <div className={classes.followUpHeader}>
                            {idx === 1 ? 'First' : 'Second'} Follow-Up
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    {/* customer's question section */}
                    <Grid
                      item
                      container
                      className={`${classes.customerQuestionSection} ${isFollowUp ? 'noSpace' : ''}`}
                    >
                      <Grid container className={classes.questionContainer}>
                        <Grid item xs />
                        <Grid item xs={10}>
                          <Grid container>
                            {/* question group */}
                            <Grid item style={{ flex: 1 }}>
                              <Grid container direction='column' spacing={1}>
                                <Grid item>
                                  <div className={classes.questionIntro}>Your question</div>
                                </Grid>
                                <Grid item>
                                  <Collapse in={questionCollapsed[id]} collapsedSize={43}>
                                    <Grid container direction='column' spacing={2}>
                                      {/* question message */}
                                      <Grid item>
                                        <div className={classes.questionText}>
                                          {questionSet.customer_message}
                                        </div>
                                      </Grid>
                                      {/* question attachment */}
                                      <Grid item container justifyContent='center'>
                                        {(questionSet.customer_attachment_image_url ||
                                          questionSet.customer_attachment_video_url) && (
                                          <Grid item>
                                            {questionSet.customer_attachment_video_url ? (
                                              <video className={classes.attachment} controls>
                                                <source
                                                  src={questionSet.customer_attachment_video_url}
                                                  type='video/mp4'
                                                />
                                              </video>
                                            ) : (
                                              <img
                                                src={questionSet.customer_attachment_image_url}
                                                className={classes.attachment}
                                                alt=''
                                              />
                                            )}
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Collapse>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* arrow buttons */}
                            <Grid item>
                              <Grid container justifyContent='flex-end'>
                                <Grid item>
                                  <div
                                    onClick={() => {
                                      handleChageQuestionCollapsed(id);
                                    }}
                                  >
                                    <img
                                      src={questionCollapsed[id] ? rightArrowImg : downArrowImg}
                                      alt=''
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs />
                      </Grid>
                    </Grid>
                    {/* creator's answer section */}
                    {answer ? (
                      <Grid item container className={classes.creatorAnswerSection}>
                        <Grid item xs />
                        <Grid item xs={10}>
                          <Grid container>
                            <Grid item style={{ flex: 1 }}>
                              {/* answer */}
                              <Grid container direction='column' spacing={1}>
                                <Grid item>
                                  <div className={classes.questionIntro}>My answer</div>
                                </Grid>
                                <Grid item>
                                  <Collapse in={questionCollapsed[answer.id]} collapsedSize={43}>
                                    <Grid item container direction='column' spacing={2}>
                                      {/* answer text */}
                                      <Grid item>
                                        <div className={classes.answerText}>
                                          {translatedToggledAnswer[answer.id]
                                            ? answer.original_message
                                            : answer.message}
                                        </div>
                                      </Grid>
                                      {(answer.show_clips && questionSet.clipsCreatorItem != null) && <Grid item className={classes.clipsContainer}>
                                        <div className={classes.clipsAnswerThumbnailContainer}>
                                          <img src={questionSet.clipsCreatorItem?.thumbnail} alt='thumbnail' className={classes.clipsAnswerThumbnail} />
                                          <div className={classes.clipsPlayButtonContainer} >
                                            <ClipsPlayButton onClick={() => showClipsVideo(questionSet)} isLarge={!mobileTabletView} />
                                          </div>
                                        </div>
                                      </Grid>}
                                      {answer.attachments.image_urls.map((imageUrl) => (
                                        <Grid item container justifyContent='center' key={imageUrl}>
                                          {/* image attachment */}
                                          <Grid item>
                                            <img
                                              src={imageUrl}
                                              className={classes.attachment}
                                              alt=''
                                            />
                                          </Grid>
                                        </Grid>
                                      ))}
                                      {answer.attachments.video_urls.map((videoUrl) => (
                                        <Grid item container justifyContent='center' key={videoUrl}>
                                          {/* video attachment */}
                                          <Grid item>
                                            <video className={classes.attachment} controls>
                                              <source src={videoUrl} type='video/mp4' />
                                            </video>
                                          </Grid>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </Collapse>
                                </Grid>
                              </Grid>
                            </Grid>
                            {/* arrow buttons */}
                            <Grid item>
                              <Grid container justifyContent='flex-end'>
                                <Grid item>
                                  <div
                                    onClick={() => {
                                      handleChageQuestionCollapsed(answer.id);
                                    }}
                                  >
                                    <img
                                      src={
                                        questionCollapsed[answer.id] ? rightArrowImg : downArrowImg
                                      }
                                      alt=''
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* translate content */}
                          {answer.languageMatchedWithCreators === false && (
                            <>
                              <Grid
                                container
                                justifyContent='space-between'
                                alignItems='center'
                                className={classes.mismatchContainer}
                              >
                                <Grid item>
                                  <TranslateIcon height={20} width={20} style={{ top: '3px' }} />
                                </Grid>
                                <Grid item>
                                  <div className={classes.translationIntro}>
                                    {answer.header} <span>Google</span>
                                  </div>
                                </Grid>
                              </Grid>
                              <Grid container justifyContent='center'>
                                <Grid item>
                                  <div
                                    className={classes.toggleMessage}
                                    onClick={() => handleChangeTranslatedToogledAnswer(answer.id)}
                                  >
                                    {translatedToggledAnswer[answer.id]
                                      ? answer.view_translation_txt
                                      : answer.show_original_txt}
                                  </div>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Grid item xs />
                      </Grid>
                    ) : null}
                  </Fragment>
                );
              })}

              <Grid item container>
                <Grid item xs={12}>
                  <hr className={`${classes.hr} marginTop`} />
                </Grid>
              </Grid>
            </Grid>

            {/* mid section (verified badge, downgraded question display)  */}
            <Grid
              item
              container
              direction='column'
              justifyContent={questionDowngradedToStandard && !isCompleted ? 'flex-start' : 'space-evenly'}
              className={classes.midSectionContainer}
            >
              {questionDowngradedToStandard && !isCompleted && (
                <Grid container>
                  <Grid item xs />
                  <Grid item xs={10}>
                    <Grid
                      container
                      alignItems='center'
                      className={classes.downgradedQuestionContainer}
                    >
                      <Grid item>
                        <img src={lateAnswerImg} alt='late' />
                      </Grid>
                      <Grid item xs className={classes.downgradedQuestionTextContainer}>
                        <div className={classes.downgradedQuestionText}>
                          You will not be charged for this late answer but you may still leave a tip to say thanks.
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs />
                </Grid>
              )}
              {isCompleted && !feedback && (
                <Grid item className={classes.answeredIconContainer}>
                  <Grid item container justifyContent='center'>
                    <img src={successImg} alt='' className={classes.successImg} />
                  </Grid>
                  <Grid item className={classes.answeredTextContainer}>
                    <div className={classes.followUpHeader}>Answered</div>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {is_sponsor_qna && !isCompleted && (
              <Grid item container>
                <Grid item xs />
                <Grid item xs={10}>
                  <SponsoredCard
                    displayName={display_name}
                    sponsorName={sponsor_name}
                    sponsorLogoUrl={sponsor_logo_url}
                    sponsorDescription={sponsor_description}
                    sponsorCtaCopy={sponsor_cta_copy}
                    sponsorCtaUrl={sponsor_cta_url}
                    extraContainerClass={classes.sponsorContainer}
                  />
                </Grid >
                <Grid item xs />
              </Grid>
            )}
            {/* action section */}
            {!isCompleted ? (
              <>
                {followUpSelected ? (
                  <Grid container direction='column' className={classes.actionFooter}>
                    <Grid item container justifyContent='center'>
                      <Grid item>
                        <div className={classes.goBackContainer} onClick={onResetStatus}>
                          Go Back
                        </div>
                      </Grid>
                    </Grid>

                    {areAnyFollowUpsAvailable ? (
                      <>
                        <Grid item>
                          <div className={`${classes.followUpHeader} space`}>
                            Want to ask a follow-up?
                          </div>
                        </Grid>
                        <Grid item container justifyContent='center' alignItems='center' spacing={1}>
                          <Grid item>
                            <img src={successImg} alt='' className={classes.successSmallImg} />
                          </Grid>
                          <Grid item>
                            <div className={classes.followUpDescription}>
                              {followUps} of {allowedFollowUps} follow-ups used
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <QuestionField
                            onSendFollowUpQuestion={onSendFollowUpQuestion}
                            followUpSubmissionSuccess={followUpSubmissionSuccess}
                            followUpSubmissionIsLoading={followUpSubmissionIsLoading}
                            sessionTrackingCode={session_tracking_code}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid
                          item
                          container
                          justifyContent='center'
                          alignItems='center'
                          spacing={1}
                          className={classes.spacing}
                        >
                          <Grid item>
                            <img src={errorImg} alt='' className={classes.successSmallImg} />
                          </Grid>
                          <Grid item>
                            <div className={classes.followUpDescription}>
                              <span>All</span> follow-ups used
                            </div>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          direction='column'
                          alignItems='center'
                          spacing={3}
                          className={classes.spacing}
                        >
                          <Grid item>
                            <div className={classes.followUpDescription}>
                              We're sorry you didn't get the answer you wanted! <br />
                              {!skipPayment && <span>Your card will not be charged.</span>}
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={classes.followUpDescription}>
                              Could you tell us what went wrong?
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <FeedbackField
                            creatorAsqMeTag={creator_asqMe_tag}
                            onSendFeedback={onSendFeedback}
                            feedbackSubmissionSuccess={feedbackSubmissionSuccess}
                            feedbackSubmissionIsLoading={feedbackSubmissionIsLoading}
                            sessionTrackingCode={session_tracking_code}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                ) : waitingForCreatorsFollowUpAnswer ? (
                  <Grid item container className={classes.replySoonFooter}>
                    <Grid item xs />
                    <Grid item xs={10}>
                      <div className={classes.replySoonText}>
                        {display_name} will reply to your follow-up question soon!
                      </div>
                    </Grid>
                    <Grid item xs />
                  </Grid>
                ) : (
                  <Grid item container className={classes.actionFooter}>
                    <Grid item xs />
                    <Grid item xs={10}>
                      <Grid
                        container
                        direction='column'
                        spacing={2}
                        className={classes.conclusionContainer}
                      >
                        <Grid item>
                          <div className={classes.conclusion}>Did I answer your question?</div>
                        </Grid>
                        <Grid item container spacing={2} alignItems='center'>
                        <Grid item xs={6}>
                            <Grid container direction='column' alignItems='center'>
                              <Grid item container>
                                <Grid item xs={12}>
                                  <AppButton
                                    variant='secondary'
                                    className={classes.noBtn}
                                    fixedWidth='100%'
                                    onClick={() => {
                                      onNoButton();
                                    }}
                                  >
                                    {areAnyFollowUpsAvailable ? (
                                      <>Ask Follow Up</>
                                    ) : (
                                      <>No</>
                                    )}
                                  </AppButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container direction='column' alignItems='center'>
                              <Grid item container>
                                <Grid item xs={12}>
                                  <AppButton
                                    className={classes.yesBtn}
                                    fixedWidth='100%'
                                    disabled={paymentIsloading || acceptAnswerIsLoading}
                                    onClick={() => {
                                      onYesButton();
                                    }}
                                  >
                                    {paymentIsloading || acceptAnswerIsLoading ? (
                                      <CircularProgress color='inherit' size={31} />
                                    ) : (
                                      'Yes'
                                    )}
                                  </AppButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item className={classes.actionTextContainer}>
                          <div className={classes.actionText}>
                            {areAnyFollowUpsAvailable ? (
                              <>
                                {followUps === 0 ? (
                                  <>You have 24 hours to ask your first follow up question.</>
                                ) : (
                                  <>You have 24 hours to ask your final follow up question.</>
                                )}
                              </>
                            ) : (
                              <>
                                Give Me Feedback
                              </>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs />
                  </Grid>
                )}
              </>
            ) : (
              <Grid container className={`${classes.actionFooter} bottomPadding`}>
                <Grid item xs />
                <Grid item xs={10}>
                  <Grid container direction='column' spacing={1}>
                    {feedback ? (
                      <>
                        <Grid item container justifyContent='center'>
                          <img src={errorImg} alt='' className={classes.successImg} />
                        </Grid>
                        <Grid item>
                          <div className={classes.followUpHeader}>Not Answered</div>
                        </Grid>
                        <Grid item>
                          <div className={`${classes.questionIntro} spaceTop`}>Your feedback</div>
                        </Grid>
                        <Grid item>
                          <div className={classes.questionText}>{feedback}</div>
                        </Grid>
                        {is_sponsor_qna && (
                          <SponsoredCard
                            displayName={display_name}
                            sponsorName={sponsor_name}
                            sponsorLogoUrl={sponsor_logo_url}
                            sponsorDescription={sponsor_description}
                            sponsorCtaCopy={sponsor_cta_copy}
                            sponsorCtaUrl={sponsor_cta_url}
                            extraContainerClass={classes.sponsorContainer}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {!hidePaymentTextInCompletedStatus ? (
                          <Grid item>
                            <div className={classes.completedPaymentText}>Payment completed.</div>
                          </Grid>
                        ) : null}
                        {is_sponsor_qna && (
                          <SponsoredCard
                            displayName={display_name}
                            sponsorName={sponsor_name}
                            sponsorLogoUrl={sponsor_logo_url}
                            sponsorDescription={sponsor_description}
                            sponsorCtaCopy={sponsor_cta_copy}
                            sponsorCtaUrl={sponsor_cta_url}
                            extraContainerClass={classes.sponsorContainer}
                          />
                        )}
                        <Grid item container justifyContent='center' spacing={1} className={`${classes.answeredActionButtonContainer} ${is_sponsor_qna ? 'noSpace' : ''}`}>
                          <Grid item lg={6} xs={12}>
                            <AsqButton
                              extraClassName={`${classes.answeredActionButton} ${isThankBankCompleted ? '' : classes.outlinedButton}`}
                              fullWidth={true}
                              onClick={() => navigate(`/${answerData.creator_asqMe_tag}`)}
                            >
                              <SpeechBubbleIcon fillColor={isThankBankCompleted ? 'white' : '#495EFF'} />
                              Ask Another Question
                            </AsqButton>
                          </Grid>
                          {!isThankBankCompleted && <Grid item lg={6} xs={12}>
                            <AsqButton
                              extraClassName={classes.answeredActionButton}
                              fullWidth={true}
                              onClick={() => navigate(`/thx/${answerData.thankBankSession.thank_bank_session_tracking_code}`)}
                            >
                              Say Thanks!
                            </AsqButton>
                          </Grid>}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs />
              </Grid>
            )}

            {/* answer prompt modal */}
            <Fade in={verifiedAnswerModalOpen}>
              <Grid
                item
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                className={classes.sendAnswerPrompt}
              >
                <Grid item container direction='column' spacing={3} className={classes.promptCard}>
                  <Grid item>
                    <div className={classes.promptCloseIcon}>
                      <CloseIcon onClick={() => setVerifiedAnswerModalOpen(false)} />
                    </div>
                    <div className={classes.promptCopilotIcon}>
                      <img src={verifiedBadge} alt='' />
                    </div>
                    <div className={classes.promptHeader}>
                      Expert Verified<sup>TM</sup>
                    </div>
                  </Grid>
                  <Grid item container direction='column' spacing={2}>
                    <Grid item>
                      <div className={classes.promptBody}>
                        This answer was generated and verified by <span>{display_name}</span>.
                      </div>
                    </Grid>
                    <Grid item>
                      <div className={classes.promptBody}>
                        Uninformed sources or unsupervised artificial intelligence may produce
                        inaccuracies and false information.
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent='center'
                    classes={{ root: classes.promptFooter }}
                  >
                    <Grid item>
                      <Button
                        onClick={() => setVerifiedAnswerModalOpen(false)}
                        className={`${classes.promptBtn} send`}
                      >
                        Got it!
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          </Grid>
        </Grid>
        <Grid item sm xs />
      </Grid>
      <ClipsVideoPlayerDialog video={clipsVideo} onClose={() => setClipsVideo(null)} />
    </Grid>
  );
};

export default AnswerFlow;
