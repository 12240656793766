import { commonBtnStyles } from 'assets/styles/common';

const styles = (theme) => ({
  homeContainer: {
    height: '100%',
  },
  form: {
    height: '100%',
  },
  inputError: {
    fontFamily: 'Figtree-Regular',
    fontSize: 12,
    lineHeight: '16.34px',
    color: theme.error.main,
  },
  homeInnerContainer: {
    height: '100%',
  },
  dataInputSection: {
    flexGrow: 1,
    height: '100%',
  },
  sectionSpacing: {
    marginTop: theme.spacing(4),
  },
  spacing: {
    paddingTop: theme.spacing(2),
  },
  subSpacing: {
    paddingTop: theme.spacing(1),
  },
  smallFooterSpacing: {
    paddingTop: theme.spacing(1),
  },
  backdrop: { zIndex: theme.zIndex.drawer + 1, color: theme.common.white },
  imageBackDrop: {
    position: 'absolute',
    zIndex: 5,
    color: theme.common.white,
    backgroundColor: 'transparent',
  },
  headerLogosContainer: {
    position: 'relative',
    backgroundColor: theme.common.black,
    height: 100,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  editBtnIconContainer: { position: 'absolute', right: 8, top: 8 },
  editBtnImg: {
    cursor: 'pointer',
    width: '90%',
  },
  logoPlaceholderIcon: {
    transform: 'translateY(100%)',
    borderRadius: 50,
    width: 65,
    height: 65,
    backgroundColor: theme.common.black,
    border: `2px solid ${theme.common.white}`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '& img': {
      width: '50%',
      position: 'absolute',
      top: -8,
      right: -8,
    },
  },
  inputLabel: {
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1.05rem',
  },
  requiredMark: {
    color: theme.error.main,
  },
  infoImgContainer: {
    position: 'relative',
    cursor: 'pointer',
    bottom: '2px',
    marginLeft: 5,
  },
  infoIconImg: {
    position: 'relative',
    // zIndex: 1000,
    width: theme.spacing(2.2),
    height: theme.spacing(2.2),
  },
  asqMeHandleInputContainer: {
    gap: 10
  },
  prefixLabel: {
    fontFamily: 'Figtree-Bold',
    fontSize: '0.9rem',
    opacity: 0.5,
    '&.ctaUrl': {
      marginRight: 5,
    },
  },
  chainIcon: {
    width: theme.spacing(4.3),
    cursor: 'pointer',
  },
  priceOptionOuterContainer: {
    flex: "0 0 25%",
    [theme.breakpoints.down('sm')]: {
      flex: "0 0 50%"
    },
  },
  priceOptionContainer: {
    border: `3px solid ${theme.grey[500]}`,
    borderRadius: 10,
    height: 103,
    cursor: 'pointer',
    transition: 'all .1s ease-in-out',
    '&.selected': {
      borderColor: theme.blue,
    },
  },
  priceOptionText: {
    minWidth: 48,
    fontFamily: 'Figtree-Bold',
    textAlign: 'center',
    fontSize: '0.9rem',
    lineHeight: 1.2,
  },
  customPriceInputContainer: {
    marginBottom: 16,
  },
  textField: {
    '& .MuiFilledInput-adornedEnd': {
      paddingRight: theme.spacing(0.7),
    },
    '& .MuiInputAdornment-filled.MuiInputAdornment-positionStart': {
      marginTop: 0,
    },
    '& .MuiFilledInput-root': {
      borderRadius: 0,
    },
    '& .MuiFilledInput-multiline': {
      borderRadius: 0,
    },
    '& .MuiFilledInput-input': {
      padding: `${theme.spacing(1.9)}px ${theme.spacing(1.5)}px ${theme.spacing(1.9)}px`,
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.error.dark,
      fontFamily: 'Figtree-Bold',
      fontSize: theme.spacing(1.6),
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: 0,
    },
    '&.textarea': {
      width: '90%',
    },
    '&.space': {
      width: '95%',
    },
  },
  radioGroup: {
    width: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    '& label:first-child': {
      borderTopLeftRadius: 50,
      borderBottomLeftRadius: 50,
    },
    '& label:last-child': {
      borderTopRightRadius: 50,
      borderBottomRightRadius: 50,
    },
    '& label': {
      padding: 0,
      border: 'none',
      flexGrow: 1,
      textAlign: 'center',
    },
    '& .ant-radio-button-wrapper:hover': {
      color: theme.blue,
    },
    '& .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)': {
      color: theme.common.white,
      backgroundColor: theme.blue,
      boxShadow: 'none',
      '&:before': {
        backgroundColor: '#d9d9d9',
      },
    },
  },
  radioGroupErrorText: {
    lineHeight: 2.5,
    color: theme.error.dark,
    fontFamily: 'Figtree-Bold',
    fontSize: theme.spacing(1.6),
  },
  zeroChargeContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.common.black,
    color: theme.common.white,
    borderRadius: 5,
  },
  zeroChargeHeader: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  zeroChargeBody: {
    paddingTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.grey[300],
    '& span': {
      fontFamily: 'Figtree-Bold',
    },
  },
  tipsBody: {
    paddingTop: theme.spacing(2.5),
    textAlign: 'center',
    color: theme.grey[300],
    '& span': {
      fontFamily: 'Figtree-Bold',
    },
  },
  greenSpan: {
    color: theme.lightGreenLogo,
  },
  zeroChangePriceContainer: {
    paddingTop: theme.spacing(1),
    '& span': {
      fontFamily: 'Figtree-Bold',
    },
  },
  zeroChargePrice: {
    backgroundColor: theme.lightGreenLogo,
    padding: theme.spacing(0.6),
    borderRadius: 50,
    color: theme.common.black,
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
  },
  learnMoreLink: {
    marginTop: theme.spacing(1.5),
    padding: `${theme.spacing(1)}px 0`,
    width: '100%',
    ...commonBtnStyles,
    backgroundColor: theme.common.white,
    fontFamily: 'Figtree-Bold',
    color: theme.blue,
    fontSize: '1.2rem',
    textAlign: 'center',
    borderRadius: 5,
    cursor: 'pointer',
    '&:hover': {
      color: theme.blue,
      opacity: 0.8,
    },
    '&.topSpace': {
      marginTop: theme.spacing(2.5),
    },
  },
  dollarSign: {
    fontFamily: 'Figtree-Bold',
  },
  tagHelperText: {
    height: 0,
    padding: `1px 0 4px 0`,
    color: theme.error.dark,
    fontFamily: 'Figtree-Bold',
    fontSize: theme.spacing(1.6),
    display: 'flex',
    marginTop: 4,
    '& span:last-child': {
      paddingLeft: theme.spacing(0.5),
    },
    '&.success': {
      color: theme.success.light,
    },
  },
  linkHeader: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
    textAlign: 'center',
  },
  sponsorLogo: {
    maxHeight: 100,
  },
  sponsorCtaLabelContainer: {
    width: 60
  },
  hr: {
    height: theme.spacing(0.4),
    backgroundColor: theme.darkHorizontalRule,
    borderRadius: 50,
    border: 0,
  },
  footerHr: {
    backgroundColor: theme.grey[500],
    height: theme.spacing(0.4),
    borderRadius: 5,
    border: 0,
  },
  select: {
    '& .select__control--is-focused': {
      borderColor: '#495EFF',
      boxShadow: '0 0 0 1px #495EFF',
    }
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 40,
  },
  paper: {
    overflowX: 'hidden',
    height: '100%',
  },
  errorText: {
    color: '#d32f2f',
    fontSize: '12.8px',
    fontFamily: 'Figtree-Bold',
  },
});

export default styles;
