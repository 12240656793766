import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Grid, makeStyles } from '@material-ui/core';
import { MinusIcon, PlusAltIcon } from 'components/Icons';
import AppLogoName from 'components/AppLogoName';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: 'white',
    borderRadius: 24,
    border: '1px solid #9396b4',
    height: '100%'
  },
  tableHeaderContainer: {
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22,
  },
  appLogoName: {
    padding: '0 20px',
    paddingTop: 20,
    paddingBottom: 10,
  },
  headerDivider: {
    border: 0,
    borderTop: '1px solid',
    marginBottom: 30,
  },
  tableHeaderTitle: {
    fontFamily: 'Figtree-SemiBold',
    fontSize: 24,
    lineHeight: 1,
    padding: '0 20px',
    marginBottom: 10,
  },
  tableHeaderSubtitle: {
    fontFamily: 'Figtree-SemiBold',
    fontSize: 20,
    lineHeight: 1,
    color: 'white',
    padding: '0 20px',
    marginBottom: 20,
  },
  tableDescription: {
    fontFamily: 'Figtree-Regular',
    fontSize: 18,
    lineHeight: 1.2,
    color: '#000543',
    padding: '30px 20px',
  },
  subSectionContainer: {
    padding: '10px 20px',
    backgroundColor: '#CFCFCF',
  },
  subSectionTitle: {
    fontFamily: 'Figtree-Bold',
    fontSize: 20,
    color: '#181818',
    lineHeight: '24px',
  },
  subSectionLine: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0',
    '& span': {
      fontFamily: 'Figtree-Regular',
      fontSize: 20,
      color: '#181818',
      lineHeight: 1.2,
    },
  },
  subSectionFooter: {
    fontFamily: 'Figtree-Regular',
    fontSize: 14,
    color: '#181818',
    lineHeight: '14.4px',
  },
  tableItemTitle: {
    cursor: 'pointer',
    padding: '15px 20px',
    fontFamily: 'Figtree-Regular',
    fontSize: 18,
    lineHeight: 1,
    color: '#000543',
    '& svg': {
      width: 16,
      height: 16,
      fill: '#000543',
    },
  },
  tableItemDescription: {
    padding: '15px 20px',
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 18,
      marginBottom: 14,
      lineHeight: 1.2,
      color: '#000543',
    }
  },
  tableItemDivider: {
    border: 0,
    borderTop: '1px solid #b1b3c6',
    borderBottom: '1px solid #5c5f87',
    margin: '0 10px'
  }
}));

const SubscriptionTable = (props) => {
  const { 
    isPro, 
    title, 
    subtitle,
    subSectionTitle,
    subSectionLine1,
    subSectionLine2,
    subSectionFooter,
    description, 
    items
  } = props;

  const classes = useStyles();

  const headerColor = isPro ? '#181818' : '#000543'
  const headerTitleColor = isPro ? '#FFD058' : '#495EFF';
  const headerBorderColor = isPro ? '#494949' : '#36396b'

  return (
    <Grid container direction='column' className={classes.tableContainer}>
      <Grid container direction='column' className={classes.tableHeaderContainer} style={{backgroundColor: headerColor}}>
        <AppLogoName isPro={isPro} className={classes.appLogoName} />
        <hr className={classes.headerDivider} style={{borderColor: headerBorderColor }} />
        <span className={classes.tableHeaderTitle} style={{color: headerTitleColor}}>{title}</span>
        <span className={classes.tableHeaderSubtitle}>{subtitle}</span>
      </Grid>
      <Grid container direction='column' className={classes.subSectionContainer}>
        <span className={classes.subSectionTitle}>{subSectionTitle}</span>
        <div className={classes.subSectionLine}>
          <span>{subSectionLine1}</span>
          <span>{subSectionLine2}</span>
        </div>
        <span className={classes.subSectionFooter}>{subSectionFooter}</span>
      </Grid>
      <Grid container direction='column'>
        <span className={classes.tableDescription}>{description}</span>
        {items.map((item, index) => (
          <Grid item key={`${isPro ? 'pro' : 'starter'}-table-${index}`}>
            <TableItem {...item} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

const TableItem = (props) => {
  const { title, description, description2 } = props;

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Grid container direction='column'>
      <hr className={classes.tableItemDivider} />
      <span
        className={classes.tableItemTitle}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <MinusIcon /> : <PlusAltIcon />} {title}
      </span>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{
              height: 0,
              transition: { ease: 'easeInOut', duration: 0.3 }
            }}
            style={{ overflow: 'hidden' }}
          >
            <Grid container className={classes.tableItemDescription}>
              <p>{description}</p>
              {description2 && <p>{description2}</p>}
            </Grid>
          </motion.div>
        )}
      </AnimatePresence>
    </Grid>
  )
}

export default SubscriptionTable;