import { Grid, makeStyles, } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    marginBottom: 5,
  },
  labelText: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
  },
  subText: {
    fontFamily: 'Figtree-Regular',
    fontSize: 14,
    lineHeight: '16px',
    marginTop: 5,
  },
  requiredMark: {
    display: 'inline-block',
    color: theme.error.main,
    marginLeft: 2,
  },
}))

const AppLabel = (props) => {
  const {
    label,
    subLabel,
    isRequired,
    extraRightComponent,
  } = props;

  const classes = useStyles();

  return (
    <Grid item container direction="column" className={classes.labelContainer}>
      <Grid container item direction="row" justifyContent="flex-start">
        <Grid item className={classes.labelText}>
          {label}{isRequired && <div className={classes.requiredMark}>*</div>}
        </Grid>
        {extraRightComponent}
      </Grid>
      {subLabel && (
        <Grid item className={classes.subText}>
          {subLabel}
        </Grid>
      )}
    </Grid>
  )
}

export default AppLabel;
