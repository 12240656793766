import { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import useAuthNavigation from './utils';
import asqmeLogoImg from 'assets/images/asqme-logo-new.png';
import copilotHappyImg from 'assets/images/copilot-happy-transparent.png';
import { commonBtnStyles } from 'assets/styles/common';
import { queryCustomerTypes } from 'store/customer';
import { queryCreatorTypes } from 'store/creator';
import SuccessInfoCard from './success-info-card';
import AsqMeCreatorHeader from 'components/Header/AsqMeCreatorHeader';
import { FollowUpIcon, QuestionAnsweredIcon, ReceiveAnswerIcon, SpeechBubbleIcon } from 'components/Icons';
import { SponsoredCard } from 'components/Sponsor/SponsorCard';
import AppButton from 'components/Buttons/AppButton';

const useStyles = makeStyles((theme) => ({
  successContainer: {
    height: '100%',
    backgroundColor: '#FAFAFA',
    position: 'relative',
    flexWrap: 'nowrap',
  },
  mainView: {
    maxWidth: 454,
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    },
  },
  headerContainer: {
    minHeight: 100,
    marginBottom: 60,
    width: '100%',
  },
  content: {
    '&.grow': {
      flexGrow: 1,
    },
  },
  contentInnerContainer: {
    '&.grow': {
      height: '100%',
    },
  },
  titleContainer: {
    marginBottom: 10,
  },
  contentMainText: {
    textAlign: 'center',
    fontSize: 24,
    lineHeight: '28.8px',
    fontFamily: 'Figtree-Bold',
    '&.priority': {
      fontSize: 20,
      lineHeight: '24px',
    },
  },
  contentDescription: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    lineHeight: '21.79px',
    textAlign: 'center',
    color: '#666666',
  },
  divider: {
    margin: '20px 0',
    '& hr': {
      border: '1px solid #EEEEEE'
    },
  },
  whatsNextContainer: {
    marginBottom: 5,
  },
  receiveEmailContainer: {
    marginBottom: 20,
  },
  timelineContainer: {
    marginTop: theme.spacing(2),
  },
  timelineImg: {
    paddingTop: theme.spacing(1),
    width: '100%',
    maxWidth: 330,
  },
  footer: {
    paddingBottom: theme.spacing(2),
  },
  sponsoredCard: {
    margin: '40px 0',
  },
  copilotContainer: {
    maxWidth: 360,
    padding: `${theme.spacing(0.8)}px 0`,
    backgroundColor: theme.blue,
    borderRadius: 7,
    cursor: 'pointer',
  },
  copilotIcon: {
    width: 45,
    height: 45,
  },
  copilotText: {
    fontSize: '0.95rem',
    textAlign: 'center',
    lineHeight: 1.2,
    fontFamily: 'Figtree-Bold',
    color: theme.common.white,
    paddingRight: theme.spacing(0.7),
    '& span': {
      fontFamily: 'Figtree-Regular',
      fontSize: '0.85rem',
    },
  },
  loginPromptText: {
    textAlign: 'center',
    fontSize: '0.75rem',
  },
  footerImageLogo: {
    width: 25,
    height: 35,
  },
  footertextLogo: {
    width: 80,
    height: 25,
  },
  redirectBtn: {
    ...commonBtnStyles,
    border: `3px solid ${theme.blue}`,
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      fontSize: '1.1rem',
    },
  },
  asqmeLogo: {
    width: '180px',
  },
}));

const SubmitSuccess = ({
  creatorData,
  customerData,
  isAudienceQuestionSubmitted,
  isFollowUpSubmitted,
  isFeedBackSubmitted,
  isPaymentCompleted,
  isTipPaymentCompleted,
  isAnswerAccepted,
  isQuestionSubmitted,
  questionMode,
}) => {
  const classes = useStyles();
  const {
    breakpoints,
    grey,
    blue,
    common: { black },
  } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { login } = useAuthNavigation();

  // remove queries from store to prevent accessing stale data
  useEffect(
    () => () => {
      queryClient.removeQueries({ queryKey: [queryCustomerTypes.answerStepData] });
      queryClient.removeQueries({ queryKey: [queryCustomerTypes.questionStepData] });
      queryClient.removeQueries({ queryKey: [queryCustomerTypes.trimmedAnswer] });
      queryClient.removeQueries({ queryKey: [queryCustomerTypes.wholeAnswerSet] });
      queryClient.removeQueries({ queryKey: [queryCreatorTypes.audienceQuestion] });
    },
    [],
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { 
    creatorAsqMeTag, 
    display_name,
    sponsor_qna_enabled,
    sponsor,
  } = creatorData || {};
  const { customer_name } = customerData || {};

  const title = isPaymentCompleted
    ? 'Payment Successful'
    : isTipPaymentCompleted
    ? 'Tip Sent'
    : isAnswerAccepted || isFeedBackSubmitted
    ? 'Thank you!'
    : isFollowUpSubmitted
    ? 'Follow Up Question Sent'
    : isAudienceQuestionSubmitted
    ? 'Answer Sent'
    : isQuestionSubmitted
    ? 'Question Sent!'
    : '';

  const description = isPaymentCompleted
    ? `Success! We’ve processed your card and payment is on its way to ${display_name}!`
    : isTipPaymentCompleted
    ? `Thank you! We’ve processed your card and payment is on its way to ${display_name}!`
    : isAnswerAccepted || isFeedBackSubmitted
    ? ''
    : isFollowUpSubmitted
    ? `Success! We’ve sent your follow up question to ${display_name}. Check your email soon for their response.`
    : isAudienceQuestionSubmitted
    ? `Success! We’ve sent your answer to ${customer_name}.`
    : isQuestionSubmitted
    ? `We’ve sent your question to ${display_name}.`
    : '';

  return (
    <Grid container className={classes.successContainer} alignContent='flex-start' alignItems='center' direction='column'>
      <Grid item className={classes.headerContainer}>
        <AsqMeCreatorHeader showLogo={true} showSuccessIcon={true} />
      </Grid>
      <Grid
        container
        direction='column'
        className={classes.mainView}
        style={{ flex: 1 }}
      >
        {/* success message */}
        <Grid
          item
          container
          alignItems='center'
          direction='column'
        >
          <Grid item className={classes.titleContainer}>
            <div className={classes.contentMainText}>{title}</div>
          </Grid>
          <Grid item>
            <div className={classes.contentDescription}>{description}</div>
          </Grid>
        </Grid>
        {!!isQuestionSubmitted && (
          <Grid
            item
            container
            justifyContent='center'
          >
            <Grid item xs={12} className={classes.divider}>
              <hr />
            </Grid>
            <Grid item className={classes.whatsNextContainer}>
              <div className={`${classes.contentMainText} priority`}>What's next?</div>
            </Grid>
            <Grid item className={classes.receiveEmailContainer}>
              <div className={classes.contentDescription}>
                You’ll receive an email when your answer is ready for review.
              </div>
            </Grid>
            <Grid item xs={12}>
              <SuccessInfoCard
                Icon={ReceiveAnswerIcon}
                title="Receive Answer"
                subtitle="You’ll get an email with your answer."
              />
            </Grid>
            <Grid item xs={12}>
              <SuccessInfoCard
                Icon={FollowUpIcon}
                title="Ask Optional Follow Up"
                subtitle="2 follow up questions are included."
              />
            </Grid>
            <Grid item xs={12}>
              <SuccessInfoCard
                Icon={QuestionAnsweredIcon}
                title="Question Answered"
                subtitle="Accept your answer."
              />
            </Grid>
          </Grid>
        )}
        <Grid item container alignContent='flex-end' className={classes.footer} style={{ flex: 1 }}>
          <Grid item xs />
          <Grid item xs={10}>
            <Grid container direction='column' spacing={2}>
              {/* copilot */}
              {isAudienceQuestionSubmitted && (
                <Grid item container direction='column' alignItems='center'>
                  <Grid
                    item
                    container
                    justifyContent='space-evenly'
                    alignItems='center'
                    className={classes.copilotContainer}
                    onClick={login}
                  >
                    <Grid item>
                      <img alt='copilot' src={copilotHappyImg} className={classes.copilotIcon} />
                    </Grid>
                    <Grid item>
                      <div className={classes.copilotText}>
                        Ask another question with FirstDraft
                        <br />
                        <span>Click here for an AI assist</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <div className={classes.loginPromptText}>
                      You'll be asked to sign in if logged out.
                    </div>
                  </Grid>
                </Grid>
              )}
              {sponsor_qna_enabled && (
                <Grid item>
                  <SponsoredCard
                    displayName={display_name}
                    extraContainerClass={classes.sponsoredCard}
                    sponsorName={sponsor?.name}
                    sponsorLogoUrl={sponsor?.logo_url}
                    sponsorDescription={sponsor?.description}
                    sponsorCtaCopy={sponsor?.cta_copy}
                    sponsorCtaUrl={sponsor?.cta_url}
                  />
                </Grid>
              )}
              <Grid item>
                <AppButton
                  variant='secondary'
                  fixedWidth='100%'
                  onClick={() => {
                    console.log("creatorAsqMeTag", creatorAsqMeTag);
                    window.location.href = `/${creatorAsqMeTag}`;
                  }}
                >
                  <SpeechBubbleIcon fillColor={blue} style={{ marginRight: 5 }} />Ask Another Question
                </AppButton>
              </Grid>
              <Grid item />
              {!isQuestionSubmitted && (
                <Grid item container direction='column' alignItems='center' spacing={1}>
                  <Grid item container>
                    <Grid item xs={12}>
                      <hr />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <img src={asqmeLogoImg} className={classes.asqmeLogo} alt='' />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubmitSuccess;
