import { Checkbox, Grid, InputBase, makeStyles, } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    paddingTop: 5,
  },
  checkboxContainer: {
    position: 'relative',
    right: '3px',
  },
  checkbox: {
    padding: 0,
    width: 24,
    height: 24,
    '& .MuiIconButton-label': {
      borderRadius: 2,
      border: '1px solid #CCCCCC',
      backgroundColor: 'white',
      width: 18,
      height: 18,
    },
    '&.Mui-checked .MuiIconButton-label': {
      border: 'none',
      color: '#495EFF',
    },
    '& svg': {
      display: 'none',
    },
    '&.Mui-checked svg': {
      display: 'block',
    }
  },
  checkboxLabel: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    color: '#898989',
    lineHeight: 1,
    paddingRight: 5,
  },
  terms: {
    flex: 1,
    textAlign: 'right',
    position: 'relative',
    top: '1.5px',
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 12,
      color: '#898989',
      lineHeight: 1,
      margin: 0
    },
    '& a': {
      color: '#495EFF !important',
      textDecoration: 'none',
    }
  },
  inputError: {
    fontFamily: 'Figtree-Regular',
    fontSize: 12,
    lineHeight: '16.34px',
    color: theme.error.main,
  },
}));

const EmailCheckbox = (props) => {
  const {
    value,
    onChange,
    errorMessage,
    zeroHeightError,
    containerClass,
  } = props;

  const classes = useStyles();

  return (
    <Grid item container className={containerClass}>
      <Grid container item xs={12} className={classes.inputContainer} alignItems='center'>
        <Grid item className={classes.checkboxContainer}>
          <Checkbox
            checked={value}
            className={classes.checkbox}
            color='primary'
            onChange={(event, checked) => {
              onChange(checked);
            }}
          />
        </Grid>
        <Grid item className={classes.checkboxLabel}>
          Ok to email.
        </Grid>
        <Grid item className={classes.terms}>
          <p><a href='https://asqme.com/terms' target='_blank' rel="noreferrer">Terms of Service</a> and <a href='https://asqme.com/privacy' target='_blank' rel="noreferrer">Privacy Policy</a></p>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.inputError} style={ zeroHeightError ? { height: 0 } : {}}>
        {errorMessage && <div>{errorMessage}</div>}
      </Grid>
    </Grid>
  )
}

export default EmailCheckbox;