import { useEffect } from "react";

export const MetaPixel = (props) => {
  const { pixelId } = props;

  useEffect(() => {
    if (!pixelId) return;

    const metaScriptRaw = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pixelId}');
      fbq('track', 'PageView');
    `;

    const scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.innerHTML = metaScriptRaw;

    const noscriptElement = document.createElement("noscript");
    const img = document.createElement("img");
    img.height = "1";
    img.width = "1";
    img.style.display = "none";
    img.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;
    noscriptElement.appendChild(img);

    document.head.appendChild(scriptElement);
    document.head.appendChild(noscriptElement);

    return () => {
      document.head.removeChild(scriptElement);
      document.head.removeChild(noscriptElement);
    };
  }, [pixelId]);

  return null;
};

export const GoogleTagManager = (props) => {
  const { gtmId } = props;
  
  useEffect(() => {
    if (!gtmId) return;

    const importScriptElement = document.createElement("script");
    importScriptElement.type = "text/javascript";
    importScriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${gtmId}`;

    const gtmScriptRaw = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gtmId}');
    `

    const scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.innerHTML = gtmScriptRaw;

    document.head.appendChild(importScriptElement);
    document.head.appendChild(scriptElement);

    return () => {
      document.head.removeChild(importScriptElement);
      document.head.removeChild(scriptElement);
    };
  })

  return null;
};
