import { ButtonBase, CircularProgress, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonClass: {
    height: 44,
    padding: '0 20px',
    borderRadius: 5,
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    textWrap: 'nowrap',
    '&.Mui-disabled': {
      opacity: 0.8,
    },
    '&.sm': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '&.xs': {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '&.primary': {
      backgroundColor: '#495EFF',
      color: 'white',
    },
    '&.secondary': {
      border: '2px solid #495EFF',
      color: '#495EFF',
    },
    '&.pro': {
      backgroundColor: '#FFD058',
      color: 'black',
    },
  },
}))

const AppButton = (props) => {
  const { 
    children,
    variant = 'primary', // primary, secondary, pro 
    onClick,
    to,
    isLoading,
    disabled,
    type = 'button',
    fixedWidth,
    fullWidthBreakpoint = 'xs',
    extraClasses,
  } = props;

  const classes = useStyles();

  return (
    <ButtonBase 
      onClick={onClick}
      component={to ? Link : undefined}
      to={to}
      className={`${classes.buttonClass} ${variant} ${fullWidthBreakpoint} ${extraClasses}`}
      disabled={disabled || isLoading}
      type={type}
      style={{ width: fixedWidth }}
    >
      {isLoading ? <CircularProgress color='inherit' size={31} /> : children}
    </ButtonBase>
  );
};

export default AppButton;
