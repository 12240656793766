import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { AppLogoIcon, AsqMeTextLogoIcon } from 'components/Icons';
import copilotImg from 'assets/images/copilot-nav.svg';
import balanceImg from 'assets/images/balance-nav.svg';
import inboxImg from 'assets/images/inbox-nav.svg';
import profileImg from 'assets/images/profile-nav.svg';
import settingsImg from 'assets/images/settings-nav.svg';
import useAppData from 'hooks/context';
import NavItem from 'components/NavItem';
import UpgradeToProButton from 'components/UpgradeToProButton';

const useStyles = makeStyles((theme) => ({
  navigationMenuContainer: {
    height: '100%',
    backgroundColor: '#181818',
    padding: `${theme.spacing(3)}px 0`,
    // Below is necessary due to z-index background on the subscribe confirm page
    zIndex: 2,
    position: 'relative',
    '@media (max-height: 800px)': {
      padding: `${theme.spacing(1.5)}px 0`,
    },
  },
  appName: {
    textAlign: 'center',
    height: 28
  },
  logoContainer: {
    height: 50,
    textAlign: 'center',
    marginBottom: 10,
  },
  logoImg: {
    width: 50,
    height: 50,
  },
  logoSectionBottomMargin: {
    marginBottom: 60,
  },
  linksContainer: {
    padding: '0 10px',
  },
  upgradeToProButtonContainer: {
    marginBottom: 20,
    padding: '0 30px',
    width: '100%',
  },
  logoutContainer: {
    padding: '0 30px',
  },
  logoutButton: {
    height: 44,
    borderRadius: 5,
    padding: '0 20px',
    border: '1px solid white',
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    color: 'white',
    '&:hover': {
      color: 'white !important',
    },
    width: '100%',
  },
  hr: {
    width: 97,
    margin: 'auto',
    backgroundColor: theme.grey[700],
    height: 3,
    border: 0,
  },
  bottomMargin: {
    marginBottom: 46,
  },
}));

const NavigationMenu = () => {
  const classes = useStyles();

  const {
    premiumYellow,
    common: { white },
  } = useTheme();

  const { user, balance } = useAppData();

  const appLogoColorProps = user.is_subscribed_and_profile_completed
    ? { strokeColor: premiumYellow, fillColor: white }
    : {};

  const asqmeTextColorProps = user.is_subscribed_and_profile_completed
    ? { strokeColor: white, fillColor: premiumYellow }
    : {};

  const logoNavigationUrl = user.is_profile_completed ? '/inbox' : '/profile';

  return (
    <>
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        alignItems='center'
        className={classes.navigationMenuContainer}
      >
        {/* 1st section */}
        <Grid item container direction='column'>
          {/* logo */}
          <Grid item container direction='column' justifyContent='center' alignItems='center' className={classes.logoSectionBottomMargin}>
            <Grid item className={classes.logoContainer}>
              <Link to={logoNavigationUrl}>
                <AppLogoIcon {...appLogoColorProps} className={classes.logoImg} />
              </Link>
            </Grid>
            <Grid item>
              <div className={classes.appName}>
                <AsqMeTextLogoIcon {...asqmeTextColorProps} />
              </div>
            </Grid>
          </Grid>
          {/* <Grid item className={classes.logoSectionBottomMargin}>
            <hr className={classes.hr} />
          </Grid> */}
          {/* links */}
          <Grid
            item
            container
            direction='column'
            alignItems='center'
            className={classes.linksContainer}
          >
            <NavItem 
              icon={inboxImg} 
              label='Inbox'
              path='/inbox'
            />
            <NavItem 
              icon={copilotImg} 
              label='FirstDraft'
              path='/FirstDraft' 
            />
            {(+balance?.balance > 0 || user.price_per_question !== '0') && (<NavItem 
              icon={balanceImg} 
              label='Balance'
              path='/balance'
            />)}
            <NavItem 
              icon={profileImg} 
              label='Profile'
              path='/profile'
            />
            <NavItem 
              icon={settingsImg} 
              label='Settings'
              path='/account'
            />
          </Grid>
        </Grid>
        {/* 2nd section */}
        <Grid item container direction='column' alignContent='center'>
          <Grid item className={classes.upgradeToProButtonContainer}>
            <UpgradeToProButton isInNav fixedWidth='100%' />
          </Grid>
          <Grid item container direction='row' justifyContent='center' className={classes.logoutContainer}>
            <ButtonBase 
              className={classes.logoutButton} 
              to='/logout'
              component={Link}
            >
              Log Out
            </ButtonBase>
          </Grid>
          <Grid item className={classes.bottomMargin}>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NavigationMenu;
