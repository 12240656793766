const Away = (props) => (
  <svg 
    width="50"
    height="50"
    viewBox="0 0 1075 1046" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_11_24)">
      <path d="M1073.52 816.94L1022.68 445.4C1014.97 389.05 962.87 349.5 906.53 357.21L840.17 366.28L626.92 153.03C635.4 137.96 640.35 121.05 640.35 103.11C640.35 46.24 594.1 -0.0100021 537.23 -0.0100021C480.36 -0.0100021 434.11 46.24 434.11 103.11C434.11 121.13 439.08 138.11 447.5 153.08L137.1 462.49L89.1501 469.05C32.8001 476.76 -6.74991 528.86 0.960089 585.2L51.8001 956.74C59.5101 1013.09 111.61 1052.64 167.95 1044.93L985.33 933.09C1041.68 925.38 1081.23 873.28 1073.52 816.94ZM537.22 56.25C563.07 56.25 584.1 77.27 584.1 103.12C584.1 115.68 579.27 127.29 569.96 136.37C552.82 153.95 522.13 154.46 503.97 135.86C495.18 127.29 490.35 115.68 490.35 103.12C490.35 77.27 511.37 56.24 537.23 56.24L537.22 56.25ZM486.84 192.81C502.05 201.37 519.12 206.37 537.23 206.37C555.34 206.37 572.55 201.35 587.67 192.86L770.62 375.81L228.76 449.95L486.84 192.81ZM977.7 877.36L160.32 989.2C134.7 992.7 111.03 974.73 107.52 949.11L56.6801 577.57C53.1801 551.95 71.1501 528.28 96.7701 524.77L914.15 412.93C939.77 409.43 963.44 427.4 966.95 453.02L1017.79 824.56C1021.29 850.18 1003.32 873.85 977.7 877.36Z" fill="#495EFF"/>
      <path d="M732.29 674.38C717.58 566.83 618.08 491.29 510.54 506.01C403 520.72 327.45 620.22 342.17 727.76C356.89 835.3 456.38 910.85 563.92 896.13C671.46 881.41 747.01 781.92 732.29 674.38ZM646.87 756.58C639.92 770.47 623.03 776.1 609.14 769.16L524.45 726.6C514.63 721.69 508.94 711.8 508.91 701.52L491.46 571.6C489.35 556.21 500.12 542.03 515.51 539.92C530.9 537.81 545.08 548.58 547.19 563.97L563.16 683.06L634.3 718.85C648.19 725.8 653.82 742.69 646.88 756.58H646.87Z" fill="#495EFF"/>
    </g>
    <defs>
    <clipPath id="clip0_11_24">
      <rect width="1074.48" height="1045.89" fill="white"/>
    </clipPath>
    </defs>
  </svg>
);

export default Away;