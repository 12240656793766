const ThankBankLogoNoBg = ({
  width="34",
  height="34",
  fillColor="#495EFF",
  ...rest
}) => (
  <svg 
    width={width}
    height={height} 
    viewBox="0 0 34 34" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M12.2596 0C9.86388 0 7.92789 1.94111 7.92789 4.33173C7.92789 5.59345 8.45914 6.72746 9.31731 7.51923C11.1818 6.88582 13.2761 6.53846 15.5288 6.53846C15.6719 6.53846 15.7945 6.57422 15.9375 6.57933C16.3308 5.92548 16.5913 5.14904 16.5913 4.33173C16.5913 1.94111 14.6553 0 12.2596 0ZM25.9904 3.84135C23.942 4.35727 21.6893 5.46575 20.351 8.41827C18.8389 8.05048 17.2145 7.84615 15.5288 7.84615C11.1205 7.84615 7.52945 9.17428 5.06731 11.4832C4.71484 11.8152 3.55529 13.649 2.77885 13.3221C0.388221 12.3209 1.85938 8.92909 4.41346 10.7885C3.43269 7.92788 0 9.01082 0 12.0553C0 14.9363 2.39063 15.1355 1.92067 16.6731C1.55288 17.8684 1.38942 19.1659 1.38942 20.5553C1.38942 23.7888 3.79027 26.2458 5.51683 27.625C5.76202 27.8191 6.22686 28.1307 5.96635 28.9327C5.70583 29.7347 5.27164 30.8534 5.27164 30.8534C5.0009 31.5685 5.33293 32.3398 6.04808 32.6106L8.66346 33.5913C9.37861 33.8621 10.1499 33.53 10.4207 32.8149C10.4207 32.8149 10.7936 31.9261 11.0745 31.1803C11.6058 29.7755 11.9123 30.8942 15.5288 30.8942C19.2936 30.8942 19.6767 30.1484 20.0649 31.3029C20.3203 32.0538 20.637 32.8149 20.637 32.8149C20.9078 33.53 21.6791 33.8621 22.3942 33.5913L25.0096 32.6106C25.7248 32.3398 26.0568 31.5685 25.7861 30.8534L25.3774 29.75C25.3774 29.75 24.9636 29.0144 25.8678 28.524C27.6761 27.5433 29.0349 26.0721 29.9135 24.2332C30.1025 23.8398 30.3783 23.2933 31.2212 23.2933H32.5289C33.2491 23.2933 33.8365 22.7058 33.8365 21.9856V18.0625C33.8365 17.3422 33.2491 16.7548 32.5289 16.7548H31.2212C30.5367 16.7548 30.2608 16.4381 30.1587 16.1418C29.372 13.9402 28.0285 11.9276 26.1947 10.9111C25.9087 10.7527 25.9751 13.1689 26.8486 14.9159C26.5472 14.6809 24.933 13.2251 24.7236 10.6659C24.5141 8.10156 24.9739 6.57933 25.9904 3.84135ZM26.6442 16.7548C27.7272 16.7548 28.6058 17.6334 28.6058 18.7163C28.6058 19.7993 27.7272 20.6779 26.6442 20.6779C25.5613 20.6779 24.6827 19.7993 24.6827 18.7163C24.6827 17.6334 25.5613 16.7548 26.6442 16.7548Z" fill={fillColor} />
  </svg>
);

export default ThankBankLogoNoBg;