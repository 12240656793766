import thankBankLogo from 'assets/images/thank-bank-logo-dark.svg';
import percentImg from 'assets/images/percent.svg';
import noChargeImg from 'assets/images/no-charge-icon-drawer.svg';
import transferImg from 'assets/images/transfer.svg';
import qnaImg from 'assets/images/qna-white.svg';
import sponsoredQnaLogo from 'assets/images/sponsor-qna-logo.svg';
import translationImg from 'assets/images/translate.svg';
import firstDraftLogo from 'assets/images/copilot-drawer.svg';
import leadsIcon from 'assets/images/customer-export-icon-drawer.svg';

// price types
const zeroValue = '0';
const tipsValue = 'T';
const customValue = 'custom';

// Auth0 origin options
const originTypes = {
  credentials: 'auth0',
  facebook: 'facebook',
  google: 'google-oauth2',
};

// Subscription features
const popUpFeatures = {
  tipJar: {
    icon: thankBankLogo,
    title: 'ThankBank™',
    description: 'Accept tips via your favorite tipping services, including Buy Me a Coffee and Venmo.',
  },
  complimentaryAnswers: {
    icon: noChargeImg,
    title: 'Free Answers',
    description: "Don't charge for answers. Perfect if you monetize Q&A in a membership.",
  },
  leads: {
    icon: leadsIcon,
    title: 'Download Your Leads',
    description: 'Turn questions into a lead magnet. Download the contact information of people who ask you questions.',
  },
  sponsoredQna: {
    icon: sponsoredQnaLogo,
    title: 'Sponsored Q&A',
    description: 'Offer Q&A in brand sponsorships. Add a brand logo and tracking pixel to your Q page.',
  },
  noRevshare: {
    icon: percentImg,
    title: 'No Revenue Share',
    description: 'Keep 100% of what you earn. No commissions or revenue splits.',
  },
  zeroFees: {
    icon: transferImg,
    title: 'Low Transcation Fees',
    description: 'Pass-through low 2.9% transaction processing fees from Stripe.',
  },
  translation: {
    icon: translationImg,
    title: 'AutoTranslate',
    description: 'Detects and translates questions into your preferred language and back.',
  },
  qna: {
    icon: qnaImg,
    title: 'Coming Soon...',
    description: 'Q&A Catalog, Question Crawler, advanced AI features and more.',
  },
  clips: {
    icon: firstDraftLogo,
    title: 'Unlimited FirstDrafts',
    description: 'FirstDraft uses AI to craft answers for you based on your existing content. Just review and reply!',
  },
};

const thankBankPaymentMethods = {
  card: 'CARD',
  cashApp: 'CASH_APP',
  payPal: 'PAYPAL',
  buyMeACoffee: 'BUY_ME_A_COFFEE',
  venmo: 'VENMO',
}

const thankBankSessionUpdateAction = {
  updateRating: 'UPDATE_RAITING',
  submitRating: 'SUBMIT_RATING',
  submitComment: 'SUBMIT_COMMENT',
}

const thankBankSessionCompleteStatus = {
  notCompleted: 'Not Completed',
  allDoneCta: 'All Done CTA',
  externalTip: 'External Tip',
  internalTip: 'Internal Tip',
  allActionsComplete: 'All Actions Complete',
  timedOut24hr: '24 Hrs Time-out',
  timedOut1hr: '1 Hr Time-out',
}

const defaultAlertError = {
  title: 'Something went wrong',
  body: 'We\'re sorry. Please try again.',
};

const googleError = {
  insufficientPermissions: 'insufficient_permissions',
  noChannel: 'no_channel',
  quotaExceeded: 'quota_exceeded',
  genericAuthError: 'generic_auth_error',
}

const questionPassReasons = {
  notAnExpert: 'not_an_expert',
  seekProfessionalAdvice: 'seek_professional_advice',
  tooBusy: 'too_busy',
  tooManyQuestions: 'too_many_questions',
  offensiveContent: 'offensive_content',
  somethingElse: 'something_else'
}

const questionPassOptions = [
  { label: 'Not my area of expertise', value: questionPassReasons.notAnExpert },
  { label: 'They should seek professional advice', value: questionPassReasons.seekProfessionalAdvice },
  { label: 'Too busy to answer right now', value: questionPassReasons.tooBusy },
  { label: 'Asked too many questions', value: questionPassReasons.tooManyQuestions },
  { label: 'Offensive content', value: questionPassReasons.offensiveContent },
  { label: 'Something else', value: questionPassReasons.somethingElse },
]

const getQuestionPassReasonLabel = (reason) => {
  return questionPassOptions.find(option => option.value === reason)?.label;
}

export { 
  zeroValue,
  tipsValue,
  customValue,
  originTypes,
  popUpFeatures,
  thankBankSessionCompleteStatus,
  thankBankPaymentMethods,
  thankBankSessionUpdateAction,
  defaultAlertError,
  googleError,
  questionPassReasons,
  questionPassOptions,
  getQuestionPassReasonLabel,
};
