import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const ZeroChargeIcon = ({
  className = '',
  style = {},
  width = 34,
  height = 34,
  fillColor = '#898A8D',
  strokeColor = common.black,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    fill='none'
    viewBox="30 30 1140 1140"
  >
    <path d="m640.8 550.8h-2.3984v-132h2.3984c25.199 0 49.199 15.602 60 38.398 10.801 24 39.602 34.801 64.801 24 24-10.801 34.801-39.602 24-64.801-26.398-57.602-85.199-94.801-147.6-94.801h-2.3984v-72c0-21.602-16.801-38.398-38.398-38.398-21.602 0-38.398 16.801-38.398 38.398v72h-2.3984c-43.199 0-84 16.801-115.2 48-31.199 31.199-48 72-48 115.2-1.207 90.004 71.992 163.2 161.99 163.2h2.3984v132h-2.3984c-26.398 0-49.199-14.398-60-38.398s-40.801-34.801-64.801-24c-24 10.801-34.801 40.801-24 64.801 26.398 57.602 85.199 94.801 148.8 94.801h2.3984v72c0 21.602 16.801 38.398 38.398 38.398 21.602 0 38.398-16.801 38.398-38.398v-72h2.3984c90 0 163.2-73.199 163.2-163.2 0-43.199-16.801-84-46.801-115.2-31.191-30.004-73.191-48.004-116.39-48.004zm-81.602 0c-36 0-66-30-66-66 0-18 7.1992-34.801 19.199-46.801 13.199-13.199 28.801-19.199 46.801-19.199h2.3984v132zm81.602 230.4h-2.3984l-0.003906-133.2h2.3984c18 0 34.801 7.1992 46.801 19.199s19.199 28.801 19.199 46.801c0.003906 37.199-29.996 67.199-65.996 67.199z" fill={fillColor} />
    <path d="m600 30c-314.4 0-570 255.6-570 570s255.6 570 570 570 570-255.6 570-570-255.6-570-570-570zm-472.8 570c0-112.8 39.602-216 105.6-297.6l66 66c9.6016 9.6016 21.602 14.398 34.801 14.398 13.199 0 25.199-4.8008 34.801-14.398 19.199-19.199 19.199-50.398 0-68.398l-66-66c81.602-66 184.8-105.6 297.6-105.6 260.4 0 472.8 212.4 472.8 472.8 0 112.8-39.602 216-105.6 297.6l-67.203-67.199c-19.199-19.199-49.199-19.199-68.398 0-19.199 19.199-19.199 50.398 0 68.398l66 66c-81.602 67.199-184.8 106.8-297.6 106.8-260.4 0-472.8-212.4-472.8-472.8z" fill={fillColor} />
  </svg>
);

ZeroChargeIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  strokeColor: string,
  fillColor: string,
};

export default ZeroChargeIcon;
