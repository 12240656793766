import { Elements, useStripe } from '@stripe/react-stripe-js';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageWrap from './page-wrap';
import { loadStripe } from '@stripe/stripe-js';
import { CircularProgress, makeStyles } from '@material-ui/core';
import logoImg from 'assets/images/logo-white.svg';
import logoIcon from 'assets/images/logo_rotated_black.svg';
import Grid from '@material-ui/core/Grid';
import { AppDataContext } from 'Context';
import AsqButton from './asq-button';
import ErrorSlideout from 'components/ErrorSlideout';
import useAppData from 'hooks/context';
import successImg from 'assets/images/success.svg';
import { useAlert } from 'react-alert';

const useStyles = makeStyles((theme) => ({
  confirmationContainer: {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 1,
    '&::before': {
      content: `''`,
      position: 'fixed',
      width: '500vw',
      height: '700vh',
      backgroundColor: '#181818',
      zIndex: -1,
      background: `url(${logoIcon}) 0 0 repeat`,
      transform: 'translate(-50%, -50%) rotate(-30deg)',
    },
  },
  headerImage: {
    width: 58,
    marginTop: 20
  },
  gridContainer: {
    height: '100dvh',
    width: '100%',
    color: 'white',
  },
  asqmeLogo: {
    height: '30px',
  },
  premiumText: {
    color: '#FFD058',
    fontSize: 28,
  },
  planText: {
    color: 'white',
    fontSize: 28,
  },
  subscriptionConfirmedText: {
    color: 'white',
    fontSize: 24,
    marginTop: '20px',
    textAlign: 'center',
  },
  checkMarkCircle: {
    width: '43px',
    height: '43px',
    lineHeight: '43px',
    borderRadius: '50%',
    background: '#AEDC20',
    textAlign: 'center',
    fontSize: 28,
    fontWeight: 'bold',
    margin: '20px',
  },
  cardChargedText: {
    color: '#999999',
    fontSize: 16,
    lineHeight: '19.2px',
    textAlign: 'center',
    marginLeft: '52px',
    marginTop: '15px',
    marginRight: '52px',
  },
  thankyouText: {
    color: 'white',
    fontSize: 20,
    marginTop: '10px',
  },
  backButton: {
    margin: 'auto',
    maxWidth: '360px',
    marginBottom: '50px',
    backgroundColor: theme.blue,
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    height: '56px',
    lineHeight: '56px',
    textAlign: 'center',
  },
}));

// Superset of PaymentIntent.Status
const statuses = {
  loading: 'loading',
  error: 'error',
  // Statuses below come from paymentIntent.status/setupIntent.status
  canceled: 'canceled',
  processing: 'processing',
  requiresAction: 'requires_action',
  requiresCapture: 'requires_capture',
  requiresConfirmation: 'requires_confirmation',
  requiresPaymentMethod: 'requires_payment_method',
  succeeded: 'succeeded',
};

function ConfirmInner() {
  const { pollProfile } = useContext(AppDataContext);
  const classes = useStyles();

  const [searchParams] = useSearchParams();
  const paymentSecret = searchParams.get('payment_intent_client_secret');
  const setupSecret = searchParams.get('setup_intent_client_secret');
  const resubscribed = searchParams.get('resubscribed');
  const noPaymentRequired = searchParams.get('no_payment_required');

  const stripe = useStripe();

  const [status, setStatus] = useState(statuses.loading);
  const [error, setError] = useState(null);
  const [instructionsLink, setInstructionsLink] = useState(null);

  const { user } = useAppData();

  useEffect(() => {
    (async () => {
      if (!stripe) return;

      // We resumed the user's subscription or the creator subscribed using a promo code allowing them to skip payment, so show the confirmation screen
      if (resubscribed || noPaymentRequired) {
        await pollProfile((x) => x.stripe_subscription_status === 'active' || x.stripe_subscription_status === 'trialing');
        setStatus(statuses.succeeded);
        return;
      }

      if (!paymentSecret && !setupSecret) {
        setStatus(statuses.error);
        setError('Payment parameter is missing');
        return;
      }

      try {
        const isSetup = !!setupSecret;
        const method = isSetup ? stripe.retrieveSetupIntent : stripe.retrievePaymentIntent;
        const secret = setupSecret || paymentSecret;
        const intentResp = await method(secret);
        const intent = isSetup ? intentResp.setupIntent : intentResp.paymentIntent;
        if (intent) {
          const currNextChargeDate = user.stripe_subscription_info?.nextChargeDate;
          if (intent.status === statuses.succeeded) {
            await pollProfile((x) => x.is_subscribed && x.stripe_has_payment_method && x.stripe_subscription_status !== 'pending_cancellation' && x.stripe_subscription_info.nextChargeDate !== currNextChargeDate);
          }
          setStatus(intent.status);
          const cashApp = intent.next_action?.cashapp_handle_redirect_or_display_qr_code;
          if (cashApp) {
            setInstructionsLink(cashApp.hosted_instructions_url);
          }
        } else {
          setStatus(statuses.error);
          setError(intent.error?.message ?? 'Unexpected error occurred');
        }
      } catch (ex) {
        console.log(ex);
        setStatus(statuses.error);
        setError('Failed to retrieve payment status');
      }
    })();
  }, [stripe, paymentSecret, setupSecret, pollProfile, resubscribed]);

  function StatusComponent() {
    switch (status) {
      case statuses.loading:
        return <LoadingView />;
      case statuses.error:
        return <ErrorMessageView error={error} />;
      case statuses.processing:
      case statuses.requiresAction:
      case statuses.requiresCapture:
      case statuses.requiresConfirmation:
        return <ProcessingView />;
      case statuses.succeeded:
        return <SuccessfulPaymentView />;
      default:
        return <ErrorMessageView error={"Something went wrong"} />;
    }
  }

  function GoBackToAccountButton() {
    return (
      <Grid
        item
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          bottom: '0px',
          marginTop: 'auto',
          marginBottom: 'auto',
          width: '100%',
          paddingLeft: '10%',
          paddingRight: '10%',
        }}
      >
        <AsqButton to='/account' style={{ width: 260 }}>
          Back to Account
        </AsqButton>
      </Grid>
    );
  }

  function confirmationText() {
    if (resubscribed) {
      return "You will be charged upon renewal.";
    } else if (user?.stripe_subscription_status === "trialing") {
      return "You will be charged at the end of your free trial.";
    } else {
      return "You have been charged.";
    }
  }

  function SuccessfulPaymentView() {
    return (
      <>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          className={classes.gridContainer}
        >
          <Grid item style={{ marginTop: 'auto' }}>
            <img className={classes.asqmeLogo} src={logoImg} alt='AsqMe logo' />
          </Grid>
          <Grid item>
            <div className={classes.createMembershipText}>
              <span className={classes.premiumText}>Pro</span>{' '}
              <span className={classes.planText}>Plan</span>
            </div>
          </Grid>
          <Grid item>
            <img src={successImg} className={classes.headerImage} alt='success' />
          </Grid>
          <Grid item>
            <div className={classes.subscriptionConfirmedText}>Subscription confirmed!</div>
          </Grid>
          <Grid item>
            <div className={classes.cardChargedText}>
              {confirmationText()}
            </div>
          </Grid>
          <GoBackToAccountButton />
        </Grid>
      </>
    );
  }

  function ProcessingView() {
    return (
      <>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          className={classes.gridContainer}
        >
          <Grid item>
            <img className={classes.asqmeLogo} src={logoImg} alt='AsqMe logo' />
          </Grid>
          <Grid item>
            <div className={classes.subscriptionConfirmedText}>Payment pending</div>
          </Grid>
          <Grid item>
            <div className={classes.cardChargedText}>
              Please check back later once the payment has been completed.
            </div>
          </Grid>
          {instructionsLink && (
            <Grid item>
              <div className={classes.cardChargedText}>
                <a href={instructionsLink} target='_blank' rel='noreferrer'>
                  View Instructions
                </a>
              </div>
            </Grid>
          )}
        </Grid>
      </>
    );
  }

  function LoadingView() {
    return (
      <>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          className={classes.gridContainer}
        >
          <Grid item>
            <img className={classes.asqmeLogo} src={logoImg} alt='AsqMe logo' />
          </Grid>
          <Grid item>
            <div className={classes.createMembershipText}>
              <CircularProgress
                style={{ marginTop: '40px' }}
                color='inherit'
                thickness={3}
                size={30}
              />
            </div>
          </Grid>
          <Grid item>
            <div className={classes.subscriptionConfirmedText}>Loading...</div>
          </Grid>
        </Grid>
      </>
    );
  }

  function ErrorMessageView({ error }) {
    const alert = useAlert();

    useEffect(() => {
      alert.error(null, { title: error, body: 'Update your payment method information and try again', offsetError: true })
    }, [error])

    return (
      <>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          className={classes.gridContainer}
        >
          <Grid item style={{ marginTop: 'auto' }}>
            <img className={classes.asqmeLogo} src={logoImg} alt='AsqMe logo' />
          </Grid>
          <Grid item>
            <div className={classes.subscriptionConfirmedText}>Something went wrong</div>
          </Grid>
          <GoBackToAccountButton />
        </Grid>
      </>
    );
  }

  return (
    <div className={classes.confirmationContainer}>
      <StatusComponent />
    </div>
  );
}

export default function Confirm() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <PageWrap isFullWidthChild>
      <Elements stripe={stripePromise}>
        <ConfirmInner />
      </Elements>
    </PageWrap>
  );
}
