import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  questionPassButton: {
    padding: 4,
    borderRadius: 4,
    backgroundColor: '#495EFF',
    fontFamily: 'Figtree-Regular',
    fontSize: 14,
    lineHeight: 1,
    color: 'white',
    '&.passed': {
      pointerEvents: 'none',
      backgroundColor: '#9e9e9e',
    },
    '&.gray': {
      backgroundColor: '#757575',
    },
  }
}));

const QuestionPassButton = (props) => {
  const { isPassed, onClick, extraClassName }  = props;

  const classes = useStyles();

  const text = isPassed ? 'Passed' : 'Pass';

  return (
    <ButtonBase 
      onClick={isPassed ? undefined : onClick}
      className={`${classes.questionPassButton} ${extraClassName ?? ''} ${isPassed ? 'passed' : ''}`} 
    >
      {text}
    </ButtonBase>
  )
};

export default QuestionPassButton;
