import { Grid, InputBase, makeStyles, } from "@material-ui/core"
import AppLabel from "./AppLabel";

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    width: '100%',
    border: '1px solid #CCCCCC',
    borderRadius: 3,
    padding: 0,
    backgroundColor: 'white',
  },
  root: {
    width: '100%',
    padding: 0,
  },
  input: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    padding: '10px 20px'
  },
  inputError: {
    fontFamily: 'Figtree-Regular',
    fontSize: 12,
    lineHeight: '16.34px',
    color: theme.error.main,
  },
}))

const AppTextField = (props) => {
  const {
    label,
    placeholder,
    isRequired,
    error,
    errorMessage,
    zeroHeightError,
    fieldProps,
    inputComponent,
    inputProps,
    startAdornment,
    endAdornment,
    onBlur,
    disabled,
    multiline,
    containerClass,
  } = props;

  const classes = useStyles();

  return (
    <Grid item container className={containerClass}>
      {label && <AppLabel label={label} isRequired={isRequired} />}
      <Grid container item xs={12} className={classes.inputContainer} alignContent="space-between">
        <Grid item xs={12}>
          <InputBase
            {...fieldProps}
            inputProps={inputProps}
            inputComponent={inputComponent}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            classes={{ root: classes.root, input: classes.input }}
            placeholder={placeholder}
            error={error}
            onBlur={onBlur}
            disabled={disabled}
            multiline={multiline}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.inputError} style={ zeroHeightError ? { height: 0 } : {}}>
        {errorMessage && <div>{errorMessage}</div>}
      </Grid>
    </Grid>
  )
}

export default AppTextField;