import { useState, useMemo, createContext, useEffect, useCallback, useRef } from 'react';
import jwt_decode from 'jwt-decode';
import { useAlert } from 'react-alert';
import { DateTime } from 'luxon';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import {
  applyUnauthorizedInterceptor,
  getTokenFromStorage,
  removeTokenFromApiHeaders,
  removeTokenFromStorage,
  setTokenOnApiHeaders,
  setTokenOnStorage,
} from 'utils/apiConfig';
import { queryProfileTypes, fetchProfile } from 'store/auth/profile';
import { fetchSubscription } from 'store/payment';
import { fetchExchangeRates } from 'store/exchange-rates';
import { isProdEnv } from 'config/constants';
import { getBalance } from 'store/creator';
import { clearMessengerSession, loadIntercomMessenger } from 'utils/intercomConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { convertCurrency, getCurrencySymbol, formatPrice } from 'utils/currency-utils';
import { isProfileCompleted } from 'utils/comparers';
import { zeroValue, tipsValue, defaultAlertError } from 'utils/common/constants';
import { tiktokInitialization } from 'utils/tiktokConfig';
import { segment, cio } from 'index';

const stopAuthProcess = sessionStorage.getItem('skip_authentication_process') === 'true';

const AppDataContext = createContext({});

const initialAppDataState = {
  initialLoad: true,
  auth: null,
  user: null,
  tempProfileData: null,
  subscription: null,
  balance: null,
  redirectUrl: null,
  exchangeRates: [],
  firstDraftChat: null,
  doNotShowClipsPrompt: localStorage.getItem('doNotShowClipsPrompt') === 'true',
};

const initialAuthOpts = {
  fromLogin: false,
  redirectCallback: null,
  redirectUrl: null,
};

function timeoutPromise(delayMs) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), delayMs);
  });
}

const useContextData = () => {
  const alert = useAlert();

  const queryClient = useQueryClient();

  const [appData, setAppData] = useState(
    stopAuthProcess ? { ...initialAppDataState, initialLoad: false } : initialAppDataState,
  );
  const idTrackCallInvokedRef = useRef(false);
  const { pathname, search } = window.location;
  const redirectUrlAfterAuth = useRef(pathname ? pathname + search : null);

  const { logout } = useAuth0();

  const onSetAuth = useCallback((token, authOpts) => {
    const { fromLogin, redirectCallback, redirectUrl } = authOpts || initialAuthOpts;

    setTokenOnApiHeaders(token);
    setTokenOnStorage(token);
    setAppData((prev) => ({
      ...prev,
      auth: { token, fromLogin: !!fromLogin, redirectCallback, redirectUrl },
    }));
  }, []);

  const onSetUser = useCallback((data) => {
    setAppData((prev) => {
      let newAppData = {
        ...prev,
        initialLoad: false,
        user: {
          ...data,
          is_profile_completed: isProfileCompleted(data),
          is_subscribed_and_profile_completed: data.is_subscribed && isProfileCompleted(data),
        },
      }

      // User has subscribed since last time the user data was fetched
      if (!prev.user?.is_subscribed && data.is_subscribed && prev.firstDraftChat) {
        // There might be an ongoing first draft chat. Clear the expiry message timeouts
        const oldReminderTimeoutId = prev.firstDraftChat.reminder_timeout_id;
        const oldExpiryTimeoutId = prev.firstDraftChat.expiry_timeout_id;

        if (oldReminderTimeoutId) {
          clearTimeout(oldReminderTimeoutId);
          newAppData.firstDraftChat.reminder_timeout_id = null;
        }

        if (oldExpiryTimeoutId) {
          clearTimeout(oldExpiryTimeoutId);
          newAppData.firstDraftChat.expiry_timeout_id = null;
        }
      }

      return newAppData;
    });
  }, []);

  const updateBalance = useCallback((data) => {
    setAppData((prev) => ({ ...prev, balance: { ...data } }));
  }, []);

  const convertPrice = useCallback(
    (price, options = {}) => {
      const { fromCurrency, toCurrency = 'USD', roundUp = false } = options;

      if (!fromCurrency) {
        console.error('fromCurrency is required');
        return price;
      }

      let convertedPrice = convertCurrency(price, fromCurrency, toCurrency, appData.exchangeRates);

      if (roundUp) {
        convertedPrice = Math.ceil(convertedPrice);
      }

      return convertedPrice;
    },
    [appData.exchangeRates],
  );

  const convertAndFormatPrice = useCallback(
    (price, options = {}) => {
      const { toCurrency = 'USD', appendCurrency = false, roundUp = false } = options;

      const convertedPrice = convertPrice(price, options);
      return formatPrice(convertedPrice, {
        currency: toCurrency,
        appendCurrency: appendCurrency,
        hideDecimals: roundUp,
      });
    },
    [convertPrice],
  );

  const initFirstDraftChat = useCallback((chats_used, chat_limit, firstMessage) => {
    setAppData((prev) => ({ ...prev, firstDraftChat: { chats_used, chat_limit, messages: [firstMessage] } }));
  }, []);

  const addNewMessageToFirstDraftChat = useCallback((message) => {
    setAppData((prev) => ({ ...prev, firstDraftChat: { ...prev.firstDraftChat, messages: [...prev.firstDraftChat.messages, message] } }));
  }, []);

  const setFirstDraftChatGptFollowUp = useCallback((ask_chat_gpt_follow_up) => {
    setAppData((prev) => ({ ...prev, firstDraftChat: { ...prev.firstDraftChat, ask_chat_gpt_follow_up } }));
  }, []);

  const setDoNotShowClipsPrompt = useCallback((doNotShowClipsPrompt) => {
    localStorage.setItem('doNotShowClipsPrompt', doNotShowClipsPrompt);
    setAppData((prev) => ({ ...prev, doNotShowClipsPrompt }));
  }, []);

  const setFirstDraftChatExpiry = useCallback((expiry) => {
    const oldExpiryTimeoutId = appData.firstDraftChat.expiry_timeout_id;
    const oldReminderTimeoutId = appData.firstDraftChat.reminder_timeout_id;
    if (oldExpiryTimeoutId) {
      clearTimeout(oldExpiryTimeoutId);
    }

    if (oldReminderTimeoutId) {
      clearTimeout(oldReminderTimeoutId);
    }

    let expiryTimeoutId = null;
    let reminderTimeoutId = null;
    if (expiry != null) {
      const now = new Date();
      const expiryDate = new Date(expiry);
      const expiryInMillis = expiryDate - now;
  
      expiryTimeoutId = setTimeout(() => {  
        const chatsUsed = appData.firstDraftChat.chats_used + 1;
        const chatsRemaining = appData.firstDraftChat.chat_limit - chatsUsed;
  
        addNewMessageToFirstDraftChat({ 
          type: 'assistant', 
          text: `Thank you for chatting ${appData.user.display_name}! I have closed the conversation. You have ${chatsRemaining} more conversations left this month. Upgrade to the Pro Plan for unlimited conversations and other great features.` 
        });
  
        setAppData((prev) => ({ ...prev, firstDraftChat: { ...prev.firstDraftChat, chats_used: chatsUsed, expiry_timeout_id: null, ask_chat_gpt_follow_up: false } }));
      }, expiryInMillis);

      reminderTimeoutId = setTimeout(() => {
        addNewMessageToFirstDraftChat({ 
          type: 'assistant', 
          text: 'Are you still there? Please ask your question below. Otherwise I’ll close our conversation.', 
        });

        setAppData((prev) => ({ ...prev, firstDraftChat: { ...prev.firstDraftChat, reminder_timeout_id: null } }));
      }, expiryInMillis / 2);
    }

    setAppData((prev) => ({ ...prev, firstDraftChat: { ...prev.firstDraftChat, expiry_timeout_id: expiryTimeoutId, reminder_timeout_id: reminderTimeoutId } }));
  }, [appData, addNewMessageToFirstDraftChat]);

  const onRedirectAfterLogout = useCallback(() => {
    removeTokenFromStorage();
    clearMessengerSession(); // clear intercom session on unmount
    logout({ logoutParams: { returnTo: process.env.REACT_APP_WEB_BASE_URL } });
  }, []);

  const onApplicationLogout = useCallback(() => {
    removeTokenFromApiHeaders();
    removeTokenFromStorage();

    clearMessengerSession(); // clear intercom session on unmount
    // setAppData((prev) => ({
    //   ...initialAppDataState,
    //   subscription: prev.subscription,
    //   initialLoad: false,
    // }));

    logout({ logoutParams: { returnTo: window.location.origin } });
  }, []);

  // in prod app, we need to redirect to asqme.com after logout
  const onLogout = isProdEnv ? onRedirectAfterLogout : onApplicationLogout;

  /**
   * Auth process
   * If any of the following conditions is satisfied, abort auth and navigate to login
   * 1. No token found in localstorage
   * 2. "id_token" search param exists in url (Oauth navigation from landing) -> Need for fresh token claim -> delete existing in localstorage
   * 3. Stale token in localstorage (expiration date passed)
   */
  useEffect(() => {
    if (stopAuthProcess) return;

    const token = getTokenFromStorage();
    if (!token) {
      setAppData((prev) => ({
        ...prev,
        initialLoad: false,
      }));
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('id_token')) {
      removeTokenFromStorage();
      setAppData((prev) => ({
        ...prev,
        initialLoad: false,
      }));
      return;
    }

    const { exp } = jwt_decode(token);
    const now = DateTime.now();
    const expirationDate = DateTime.fromSeconds(exp);

    if (now > expirationDate) {
      onLogout();
      return;
    }

    onSetAuth(token);
  }, []);

  useQuery({
    queryKey: ['exchangeRates'],
    queryFn: fetchExchangeRates,
    onSuccess: (data) => {
      setAppData((prev) => ({ ...prev, exchangeRates: data }));
    },
  });

  useQuery({
    queryKey: ['subscription'],
    queryFn: fetchSubscription,
    onSuccess: (data) => {
      data.price = data.priceAmount / 100;
      setAppData((prev) => ({ ...prev, subscription: data }));
    },
  });

  useQuery({
    queryKey: [queryProfileTypes.initBalance],
    queryFn: getBalance,
    enabled: !!appData.auth,
    onSuccess: (data) => {
      setAppData((prev) => ({ ...prev, balance: data }));
    },
  });

  useQuery({
    queryKey: [queryProfileTypes.profile],
    queryFn: fetchProfile,
    enabled: !!appData.auth,
    onSuccess: (data) => {
      // sign in identify
      if (!appData.user) {
        // tiktok initialization
        tiktokInitialization();

        // intercom messenger initialization
        loadIntercomMessenger({
          email: data.email,
          name: data.first_name,
          user_id: data.id,
        });
      }

      onSetUser(data);
      if (appData.auth.redirectCallback) {
        appData.auth.redirectCallback();
        setAppData((prev) => ({ ...prev, auth: { ...prev.auth, redirectCallback: null } }));
      }
      if (appData.auth.fromLogin) {
        setAppData((prev) => ({ ...prev, auth: { ...prev.auth, fromLogin: false } }));
        // alert.success(
        //   <div>
        //     <strong>Logged in successfully</strong>
        //   </div>,
        // );
      }

      applyUnauthorizedInterceptor(onLogout, alert);
    },
    onError: (error) => {
      onLogout();
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  // fire identify call once per auth session
  useEffect(() => {
    if (!appData.user || !appData.exchangeRates.length || idTrackCallInvokedRef.current) return;

    const userData = {
      asqme_tag: appData.user.asqMe_tag,
      country: appData.user.country,
      created_at: new Date().getTime(),
      display_name: appData.user.display_name,
      email: appData.user.email,
      first_name: appData.user.gmail_user_data?.given_name,
      last_name: appData.user.gmail_user_data?.family_name,
      name: appData.user.gmail_user_data?.name,
      plan_type: appData.user.is_subscribed && isProfileCompleted(appData.user) ? 'Pro' : 'Starter',
      payment_model:
        appData.user.sponsor_qna_enabled 
        ? 'Sponsored'
        : appData.user.price_per_question === zeroValue
          ? 'None'
          : appData.user.price_per_question === tipsValue
            ? 'Tip'
            : 'Price',
      question_price: [zeroValue, tipsValue].includes(appData.user.price_per_question)
        ? null
        : appData.user.price_per_question_custom
        ? formatPrice(appData.user.price_per_question_custom, {
            currency: appData.user.currency_code,
            hideDecimals: true,
          })
        : convertAndFormatPrice(appData.user.price_per_question, {
            fromCurrency: 'USD',
            toCurrency: appData.user.currency_code,
            roundUp: true,
          }),
      user_type: 'Creator',
      partner_integration: appData.user.partner_integration,
      firstdraft_enabled: appData.user.clips_enabled,
      stripe_subscription_status: appData.user.stripe_subscription_status,
      status: appData.user.is_away ? 'Away' : 'Active',
      currency_code: appData.user.currency_code,
    };

    if (segment) segment.identify(appData.user.id, userData);
    if (cio) cio.identify(appData.user.id, userData);

    idTrackCallInvokedRef.current = true;
  }, [appData.user, appData.exchangeRates]);

  useEffect(() => {
    if (!appData.auth) {
      queryClient.removeQueries({ queryKey: [queryProfileTypes.profile] });
      queryClient.removeQueries({ queryKey: [queryProfileTypes.initBalance] });
    }
  }, [appData.auth]);

  const reloadProfile = useCallback(async () => {
    const result = await fetchProfile();
    onSetUser(result);
    return result;
  }, [onSetUser]);

  const pollProfile = useCallback(
    async (predicate, maxTries = 10, delayMs = 1000) => {
      for (let i = 0; i < maxTries; i++) {
        try {
          const result = await fetchProfile();
          onSetUser(result);

          if (predicate(result)) {
            return result;
          }
        } catch (ex) {
          console.log('Failed to fetch profile', ex);
        }

        await timeoutPromise(delayMs);
      }
    },
    [onSetUser],
  );

  const contextData = useMemo(
    () => ({
      appData,
      onSetAuth,
      onSetUser,
      onLogout,
      setAppData,
      redirectUrlAfterAuth: redirectUrlAfterAuth.current,
      reloadProfile,
      pollProfile,
      updateBalance,
      convertPrice,
      convertAndFormatPrice,
      initFirstDraftChat,
      addNewMessageToFirstDraftChat,
      setFirstDraftChatGptFollowUp,
      setFirstDraftChatExpiry,
      setDoNotShowClipsPrompt
    }),
    [appData],
  );

  return contextData;
};

const AppDataProvider = ({ children }) => {
  const contextData = useContextData();

  return <AppDataContext.Provider value={contextData}>{children}</AppDataContext.Provider>;
};

export { AppDataContext, AppDataProvider };
