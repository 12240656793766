import { useEffect, useState } from 'react';
import useAppData from 'hooks/context';
import ConfirmSubscriptionSlideout from 'components/ConfirmSubscriptionSlideout';
import { fetchApi, methods } from 'utils/apiConfig';
import { useNavigate } from 'react-router-dom';
import { trackAppCtaClick } from 'store/creator';
import { getPathAndPageName } from 'utils/url-utils';
import AppButton from 'components/Buttons/AppButton';
import { makeStyles } from '@material-ui/core';
import { updateMessenger } from 'utils/intercomConfig';

const useStyles = makeStyles((theme) => ({
  subscribe: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
  },
  priceBubble: {
    background: '#FFF',
    borderRadius: '50%',
    minWidth: 30,
    color: '#000',
    padding: 5,
    marginRight: 3,
    fontFamily: 'FigTree-Bold',
    display: "flex",
    justifyContent: "center",
    marginLeft: 10,
  },
  btnInnerRight: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'initial',
    fontFamily: 'FigTree-Regular',
  },
}));

const SubscribeButton = (props) => {
  const { onClick, fixedWidth = '100%', showPrice } = props;
  const { subscription, user } = useAppData();

  const navigate = useNavigate();

  const classes = useStyles();

  const [showConfirmSlideout, setShowConfirmSlideout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function subscribe() {
    setShowConfirmSlideout(false);
    setIsLoading(true);
    try {
      const result = await fetchApi('/creator/subscribe', methods.POST, {
        data: { chargeAndStartSubscription: true },
      });

      navigate(`/subscribe/confirm?payment_intent_client_secret=${result.paymentSecret}`);
    } catch {
      navigate('/subscribe?paymentDeclined=true');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (showConfirmSlideout) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [showConfirmSlideout]);

  return (
    <>
      <AppButton
        variant={user.was_subscriber ? 'primary' : 'pro'}
        fixedWidth={fixedWidth}
        to={user.stripe_has_payment_method ? null : '/subscribe'}
        onClick={() => {
          trackAppCtaClick({
            ...getPathAndPageName(),
            cta_verbiage: 'Subscribe Button',
          });
          
          if (user.stripe_has_payment_method) {
            setShowConfirmSlideout(true)
          } else {
            onClick();
          }
        }}
        isLoading={isLoading}
      >
        {user.was_subscriber ? (
          <div className={classes.subscribe}>
            Subscribe
            {showPrice && (
              <div className={classes.btnInnerRight}>
                <div className={classes.priceBubble}>${subscription?.price}</div>/mo
              </div>
            )}
          </div>
        ) : (
          "Start AsqMe Pro Free Trial"
        )}
      </AppButton>
      <ConfirmSubscriptionSlideout
        showSlideout={showConfirmSlideout}
        onConfirm={subscribe}
        onClose={() => setShowConfirmSlideout(false)}
      />
    </>
  )
}

export default SubscribeButton;