import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 10,
    '& img': {
      maxHeight: 100,
      objectFit: 'cover',
    },
  },
  bottomTextContainer: {
    marginTop: 10,
    marginBottom: 10,
    '& p': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '21.79px',
      textAlign: 'center',
      margin: 0,
    },
  },
  qnaSponsoredText: {
    fontFamily: 'Figtree-Regular',
    fontSize: 12,
    lineHeight: 1,
    marginBottom: 10,
  },
  cta: {
    '& a': {
      fontFamily: 'Figtree-Bold',
      fontSize: 16,
      lineHeight: 1,
      color: '#495EFF',
      textDecoration: 'underline',
    }
  }
}));

export const SponsoredCard = (props) => {
  const { 
    displayName,
    sponsorName,
    sponsorLogoUrl,
    sponsorDescription,
    sponsorCtaCopy,
    sponsorCtaUrl,
    extraContainerClass
  } = props;

  const classes = useStyles();

  return (
    <Grid container item direction='column' alignItems="center" className={extraContainerClass}>
      <Grid item className={classes.qnaSponsoredText}>
         {`${displayName} is supported by ${sponsorName}.`}
      </Grid>
      <Grid 
        container 
        item
        direction='column'
        className={classes.outerContainer} 
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
      >
        {sponsorLogoUrl && (
          <Grid item>
            <img src={sponsorLogoUrl} alt="Sponsor Logo" />
          </Grid>
        )}
        {sponsorDescription && (
          <Grid item className={classes.bottomTextContainer}>
            <p>{sponsorDescription}</p>
          </Grid>
        )}
        {sponsorCtaCopy && sponsorCtaUrl && (
          <Grid item className={classes.cta}>
            <a href={`https://${sponsorCtaUrl}`} target="_blank" rel="noreferrer">
              {sponsorCtaCopy}
            </a>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}