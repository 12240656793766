import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const SponsoredQnA = ({
  className = '',
  style = {},
  width,
  height = 39,
  fillColor = '#898A8D',
  strokeColor = common.black,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    fill='none'
    viewBox="99.95 234.66 1000 730.75"
  >
    <path d="m1005.2 234.66h-464.62c-52.25 0-94.875 42.5-94.875 94.75v21.375h-250.88c-52.375 0-94.875 42.5-94.875 94.75v306.25c0 52.25 42.5 94.75 94.875 94.75h68.75v103.25c0 6.25 3.875 12 9.625 14.375 2 0.875 4 1.25 6 1.25 4.125 0 8.125-1.625 11.125-4.625l114.12-114.25h254.88c52.25 0 94.875-42.5 94.875-94.75v-21.25h41.25l114.12 114.12c3 3 7 4.625 11.125 4.625 2 0 4-0.5 6-1.25 5.75-2.375 9.625-8.125 9.625-14.375v-103.12h68.875c52.25 0 94.75-42.625 94.75-94.875v-306.25c0-52.25-42.5-94.75-94.75-94.75zm-504.62 415.25c0 31.875-24.875 58.75-57.875 65.25v17.375c0 8.625-7 15.625-15.625 15.625s-15.625-7-15.625-15.625v-17.375c-33.125-6.5-57.875-33.375-57.875-65.25 0-8.625 6.875-15.625 15.625-15.625 8.625 0 15.625 7 15.625 15.625 0 14.875 11 27.75 26.625 33v-71.625c-27.5-6.625-57.875-19-57.875-63.75 0-32 24.75-58.75 57.875-65.25v-17.5c0-8.625 7-15.625 15.625-15.625s15.625 7 15.625 15.625v17.5c33 6.5 57.875 33.25 57.875 65.25 0 8.625-7 15.625-15.625 15.625s-15.625-7-15.625-15.625c0-15-11-27.875-26.625-33v71.625c27.375 6.5 57.875 19 57.875 63.75zm568.12-14.25c0 35-28.5 63.625-63.5 63.625h-84.5c-8.625 0-15.625 6.875-15.625 15.625v81l-92.125-92.125c-2.875-2.875-6.875-4.5-11-4.5h-47.75v-253.75c0-52.25-42.625-94.75-94.875-94.75h-182.38v-21.375c0-35 28.5-63.5 63.625-63.5h464.62c35 0 63.5 28.5 63.5 63.5z" fill={fillColor} />
    <path d="m469.38 649.91c0 14.875-11 27.75-26.625 33v-64.375c19 5.5 26.625 12.5 26.625 31.375z" fill={fillColor} />
    <path d="m411.5 514.54v64.375c-19.125-5.5-26.625-12.625-26.625-31.375 0-15 11-27.875 26.625-33z" fill={fillColor} />
  </svg>
);

SponsoredQnA.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  strokeColor: string,
  fillColor: string,
};

export default SponsoredQnA;