import { useLocation } from 'react-router-dom';
import useAppData from 'hooks/context';
import { trackAppCtaClick } from 'store/creator';
import { getPathAndPageName } from 'utils/url-utils';
import AppButton from 'components/Buttons/AppButton';

const UpgradeToProButton = (props) => {
  const { children, isInNav, ...rest } = props;

  const location = useLocation();

  const { user } = useAppData();

  return (
    <>
      {(!isInNav || (!user?.is_subscribed && !location.pathname.includes("upgrade"))) && 
      <AppButton
        variant='pro'
        to='/upgrade'
        {...rest}
        onClick={() => {
          trackAppCtaClick({
            ...getPathAndPageName(),
            cta_verbiage: rest?.to === '/subscribe' ? 'Subscribe Button' : 'Go Pro Button',
          });
        }}
      >
        {children ?? 'Go Pro!'}
      </AppButton>}
    </>
  )
}

export default UpgradeToProButton;