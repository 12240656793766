import { makeStyles } from '@material-ui/core/styles';
import useAppData from 'hooks/context';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { featuresPerPriceTypes } from './constants';
import closeImg from 'assets/images/close.svg';
import goldLogoWithTitleImg from 'assets/images/gold-logo-with-title.svg';
import SubscribeButton from 'components/SubscribeButton/SubscribeButton';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    cursor: 'pointer',
  },
  drawer: {
    overflow: 'hidden',
  },
  paper: {
    overflowX: 'hidden',
    height: '100%',
    '&.maxWidth': {
      minWidth: 450,
      maxWidth: 600,
    },
  },
  drawerContainer: {
    flexGrow: 1,
    backgroundColor: theme.modalDarkBackground,
  },
  headerContainer: {
    padding: `${theme.spacing(2)}px 0px`,
    '&.shrink': {
      padding: `${theme.spacing(1)}px 0px`,
    },
  },
  headerMainSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerLogo: {
    width: 110,
  },
  headerTitle: {
    paddingLeft: theme.spacing(1),
    color: theme.premiumYellow,
    fontSize: '1.5rem',
    '& span': {
      color: theme.common.white,
    },
  },
  hr: {
    margin: `0 auto ${theme.spacing(2)}px`,
    backgroundColor: theme.grey[800],
    height: 2,
    border: 0,
    '&.shrink': {
      margin: `0 auto ${theme.spacing(1)}px`,
    },
  },
  contentContainer: { flexGrow: 1 },
  contentHeaderContainer: {
    marginTop: theme.spacing(2),
    '@media (max-height: 800px)': {
      marginTop: theme.spacing(0.5),
    },
    '@media (max-height: 630px)': {
      marginTop: theme.spacing(0),
    },
  },
  text: {
    color: theme.common.white,
    fontFamily: 'Figtree-SemiBold',
    fontSize: 32,
    textAlign: 'center',
    padding: `0 ${theme.spacing(2)}px`,
    lineHeight: 1.2,
    '& span': {
      fontFamily: 'Figtree-SemiBold',
      color: theme.premiumYellow,
    },
  },
  iconContainer: {
    marginTop: theme.spacing(4),
    '&.shrink': {
      marginTop: theme.spacing(2),
    },
    '@media (max-height: 800px)': {
      marginTop: theme.spacing(2),
    },
    '@media (max-height: 630px)': {
      marginTop: theme.spacing(2),
    },
  },
  icon: {},
  iconTitle: {
    color: theme.common.white,
    fontFamily: 'Figtree-SemiBold',
    fontSize: '1.2rem',
    '&.spaceTop': {
      '@media (max-height: 800px)': {
        fontSize: '1.1rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '1rem',
      },
    },
    '&.shrink': {
      fontSize: '1.1rem',
    },
  },
  iconDescription: {
    color: theme.grey[500],
    fontSize: '1rem',
    lineHeight: 1.3,
    '@media (max-height: 800px)': {
      fontSize: '0.9rem',
    },
    '@media (max-height: 630px)': {
      fontSize: '0.85rem',
    },
    '&.shrink': {
      fontSize: '0.9rem',
    },
  },
  icons: {
    '&.shrink': {
      width: 35,
    },
    '@media (max-height: 800px)': {
      width: 35,
    },
    '@media (max-height: 630px)': {
      width: 30,
    },
  },
  footerContainer: {
    paddingBottom: theme.spacing(2.5),
    width: '90%',
    margin: 'auto',
    marginTop: 40,
    '&.shrinkSpacing': {
      paddingBottom: theme.spacing(2),
    },
  },
}));

const FeatureInfoDrawer = ({ mobileType, type = 'free', open, onClose }) => {
  const classes = useStyles();

  const { user } = useAppData();

  const { features = [] } = featuresPerPriceTypes[type] || {};

  return (
    <Drawer
      classes={{
        root: classes.drawer,
        paper: `${classes.paper} ${!mobileType ? 'maxWidth' : ''}`,
        paperAnchorTop: classes.paper,
      }}
      transitionDuration={400}
      anchor={mobileType ? 'top' : 'right'}
      open={open}
      onClose={onClose}
    >
      <Grid container direction='column' className={classes.drawerContainer}>
        {/* header */}
        <Grid
          item
          container
          alignItems='center'
          className={`${classes.headerContainer} ${mobileType ? 'shrink' : ''}`}
        >
          <Grid item xs={11}>
            <div className={classes.headerMainSection}>
              <img
                src={goldLogoWithTitleImg}
                className={classes.headerLogo}
                alt='logo-with-title'
              />
              <div className={classes.headerTitle}>
                Pro <span>Plan</span>
              </div>
            </div>
          </Grid>
          <Grid item xs>
            <img className={classes.menuIcon} src={closeImg} onClick={onClose} />
          </Grid>
        </Grid>
        {/* hr */}
        <Grid item>
          <hr className={`${classes.hr} ${mobileType ? 'shrink' : ''}`} />
        </Grid>
        {/* content */}
        <Grid item container className={classes.contentContainer}>
          <Grid item xs />
          <Grid item xs={mobileType ? 10 : 8}>
            <Grid container direction='column'>
              {/* header */}
              <Grid item className={classes.contentHeaderContainer}>
                <div className={`${classes.text} ${!mobileType ? 'spaceTop' : 'shrink'}`}>
                  <span>Pro</span> has the perks...
                </div>
              </Grid>
              {/* features */}
              {features.map(({ icon, title, description, }) => (
                <Grid
                  key={title}
                  item
                  container
                  alignItems='center'
                  className={`${classes.iconContainer} ${mobileType ? 'shrink' : ''}`}
                  spacing={2}
                >
                  <Grid item xs={2}>
                    <img
                      src={icon}
                      alt='percent'
                      className={`${classes.icons} ${mobileType ? 'shrink' : ''}`}
                    />
                  </Grid>
                  <Grid item xs>
                    <Grid container direction='column'>
                      <Grid item>
                        <Grid container alignItems='center'>
                          <Grid item>
                            <div
                              className={`${classes.iconTitle} ${
                                mobileType ? 'shrink' : 'spaceTop'
                              }`}
                            >
                              {title}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <div className={`${classes.iconDescription} ${mobileType ? 'shrink' : ''}`}>
                          {description}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs />
        </Grid>
        <Grid item style={{ flexGrow: 1}}></Grid>
        {/* footer */}
        {!user.is_subscribed && (
          <Grid item className={`${classes.footerContainer} ${mobileType ? 'shrinkSpacing' : ''}`}>
            <SubscribeButton onClick={onClose} />
          </Grid>
        )}
      </Grid>
    </Drawer>
  );
};

export default FeatureInfoDrawer;
