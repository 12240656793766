import { makeStyles } from '@material-ui/core/styles';
import logoImg from 'assets/images/logo-white.svg';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useAlert } from 'react-alert';

import { fetchApi, methods } from 'utils/apiConfig';
import AsqButton from './asq-button';
import PageWrap from './page-wrap';
import useAppData from 'hooks/context';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { InputBase } from '@material-ui/core';
import { Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';
import AppButton from 'components/Buttons/AppButton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const useStyles = makeStyles((theme) => ({
  subscribeContainer: {
    height: '100%',
  },
  trialInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  trialInfoHeading: {
    fontFamily: 'Figtree-Bold',
    fontSize: 18,
    color: '#495EFF'
  },
  trialInfoText: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    lineHeight: '21.79px',
    textAlign: 'center',
    '& span': {
      fontFamily: 'Figtree-Bold',
    }
  },
  subscribeTopBox: {
    background: '#181818',
    borderRadius: 5,
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: '10px 30px',
    marginBottom: '20px',
  },
  subWrap: {
    padding: '20px',
  },
  priceCircle: {
    background: 'white',
    borderRadius: '50%',
    color: 'black',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    width: 40,
    fontSize: 17,
    fontFamily: 'Figtree-Bold',
    marginBottom: 5,
  },
  topRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paymentBlock: {
    width: '100%',
  },
  submitBtn: {
    marginTop: 20,
  },
  btnContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontWeight: 'bold',
    padding: 10,
  },
  card: {
    background: '#eeeeee',
    padding: 20,
    borderRadius: 5,
  },
  asqColor: {
    color: '#ffd058',
  },
  topLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  backLink: {
    color: '#666666',
    textDecoration: 'underline',
  },
  promoCodeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  promoCodeInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    // Stripe input element label styles
    '& label': {
      marginBottom: 4,
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
      fontSize: '14.88px',
      fontWeight: 400,
      color: 'rgb(48, 49, 61)'
    },
    marginTop: 10,
  },
  promoCodeInput: {
    // Matching Stripe input element styles
    padding: '0.72rem',
    backgroundColor: 'white',
    borderRadius: 5,
    transition: 'background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease',
    border: '1px solid #e6e6e6',
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: 16,
    marginRight: 12,
    '&:focus': {
      outline: 0,
      borderColor: 'hsla(233, 100%, 64%, 50%)',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(233, 100%, 64%, 25%) , 0 1px 1px 0 rgba(0, 0, 0, 0.08)',
    },
  },
  promoCodeApplyBtn: {
    height: '43.73px',
    minWidth: 141,
    '&.Mui-disabled': {
      backgroundColor: '#2B8F59',
      opacity: 1,
    },
  }
}));

function PayBlock() {
  const classes = useStyles();

  const alert = useAlert();

  const navigate = useNavigate();

  const { user } = useAppData();

  const [searchParams] = useSearchParams();

  const stripe = useStripe();
  const elements = useElements();

  const [isStripeFormComplete, setIsStripeFormComplete] = useState(false);
  const [showPaymentFields, setShowPaymentFields] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [isApplyingPromoCode, setIsApplyingPromoCode] = useState(false);

  const applyPromoCode = async () => {
    const promoCodeError = { title: 'Invalid promo code', body: 'Please enter a valid promotion code.', offsetError: true };

    if (!promoCode) {
      alert.error(null, promoCodeError);
      return;
    }

    setIsApplyingPromoCode(true);

    const result = await fetchApi(`/creator/checkPromoCode/${promoCode}`, methods.GET);

    setIsApplyingPromoCode(false);

    // A payment method is required to continue a subscription, therefore we also need to check 
    // if the promo code allows creators to continue without a payment method
    const isPromoInvalid = !result?.is_valid || (canContinueTrialSubscription && result?.hide_payment_fields);

    if (isPromoInvalid) {
      alert.error(null, promoCodeError);
      return;
    }

    setShowPaymentFields(!result.hide_payment_fields);
    setIsPromoCodeApplied(true);
  }

  // Creators who are on a trial, or have cancelled their trial, but do not have a payment method
  const canContinueTrialSubscription =
    !user.stripe_has_payment_method &&
    (user.stripe_subscription_status == 'trialing' ||
      user.stripe_subscription_status == 'pending_cancellation');

  const showPromoCodeInput = !canContinueTrialSubscription || (canContinueTrialSubscription && user.stripe_subscription_info?.discount == null);

  useEffect(() => {
    if (searchParams.get('paymentDeclined')) {
      alert.error(null, { title: 'Subscription failed', body: 'Your payment was declined. Please enter new payment details.', offsetError: true, });
    }
  }, []);

  function handleError(error) {
    setIsLoading(false);
    // Stripe already shows UI for validation errors
    if (error.type === 'validation_error') return;
    let title = error.message;
    if (error.code === 'card_declined') {
      title = 'Your card was declined';
    }
    alert.error(null, { title, body: 'Please try another payment method.', offsetError: true });
  }

  async function handleClickSubscribe(values) {
    setIsLoading(true);
    const { error: elementsError } = await elements.submit();

    if (elementsError) {
      handleError(elementsError);
      return;
    }

    try {
      const coupon = isPromoCodeApplied ? promoCode : null;

      let result;

      if (canContinueTrialSubscription) {
        result = await fetchApi('/creator/continue-trial-subscription', methods.POST, {
          data: {
            coupon
          }
        });
      } else {
        result = await fetchApi('/creator/subscribe', methods.POST, {
          data: {
            coupon,
            startSubscription: user?.was_subscriber,
          },
        });
      }

      // Creator used a promo code that allows them to skip payment
      if (result.noPaymentRequired) {
        navigate('/subscribe/confirm?no_payment_required=true');
        return;
      }

      const clientSecret = result.setupSecret ?? result.paymentSecret;
      const isSetup = !!result.setupSecret;
      const method = isSetup ? stripe.confirmSetup : stripe.confirmPayment;

      const { error: confirmError } = await method({
        clientSecret,
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/subscribe/confirm`,
        },
      });

      if (confirmError) {
        handleError(confirmError);
        return;
      }
      // At this point Stripe should redirect to confirm URL
    } catch (ex) {
      handleError(ex);
      return;
    }
  }

  function submitbuttonText() {
    if (canContinueTrialSubscription) {
      return 'Confirm payment details';
    } else if (user?.was_subscriber) {
      return 'Subscribe';
    } else {
      return 'Start AsqMe Pro Free Trial';
    }
  }

  return (
    <Formik
      initialValues={{ coupon: '' }}
      onSubmit={(values) => {
        return handleClickSubscribe(values);
      }}
    >
      {({ handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit}>
          {showPaymentFields && <PaymentElement onChange={(e) => setIsStripeFormComplete(e.complete) } />}
          {showPromoCodeInput && <div className={classes.promoCodeContainer}>
            <div className={classes.promoCodeInputContainer}>
              <label htmlFor='promoCodeInput' >Promo Code</label>
              <InputBase
                id='promoCodeInput'
                disabled={isPromoCodeApplied}
                classes={{ root: classes.promoCodeInputRoot, input: classes.promoCodeInput }}
                onChange={(e) => {
                  setPromoCode(e.target.value)
                }}
              />
            </div>
            <AsqButton 
              extraClassName={classes.promoCodeApplyBtn}
              onClick={applyPromoCode}
              isLoading={isApplyingPromoCode}
              disabled={isPromoCodeApplied}
            >
              { isPromoCodeApplied ? 'Code Applied' : 'Apply Code' }
            </AsqButton>
          </div>}
          <AppButton
            variant='pro'
            fixedWidth='100%'
            extraClasses={classes.submitBtn}
            type='submit'
            isLoading={isLoading}
            disabled={!isStripeFormComplete && showPaymentFields}
          >
            {submitbuttonText()}
          </AppButton>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
            <Link to={'..'} className={classes.backLink}>
              Nevermind
            </Link>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default function Subscribe() {
  const { subscription, user } = useAppData();
  const classes = useStyles();
  const navigate = useNavigate();
  const canContinueTrialSubscription =
    !user.stripe_has_payment_method &&
    (user.stripe_subscription_status == 'trialing' ||
      user.stripe_subscription_status == 'pending_cancellation');

  const stripeConfig = {
    stripe: stripePromise,
    options: {
      mode: 'subscription',
      amount: subscription?.priceAmount,
      currency: subscription?.currency,
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#495EFF',
        },
      },
    },
  };

  useEffect(() => {
    if (user.is_subscribed && user.stripe_has_payment_method) {
      navigate('/account');
    }
  }, [user, navigate]);

  return (
    <PageWrap>
      {!user?.was_subscriber && (
        <div className={classes.trialInfoContainer}>
          <div className={classes.trialInfoHeading}>FREE 30 Day Trial</div>
          <p className={classes.trialInfoText}>
            Try AsqMe Pro <span>free</span> for 30 days.
            <br />Automatically converts to paid subscription at end of trial. You will be reminded. Cancel any time.
          </p>
        </div>
      )}
      <div className={classes.subscribeTopBox}>
        <div className={classes.topLeft}>
          <div>
            <img src={logoImg} alt='AsqMe logo' /> <span className={classes.asqColor}>Pro</span>{' '}
            Plan
          </div>
          {!user?.was_subscriber && (
            <div>{subscription?.defaultTrialLengthDays} day free trial</div>
          )}
        </div>
        <div className={classes.topRight}>
          {!user?.was_subscriber && <div className={classes.pricePeriod}>then</div>}
          {canContinueTrialSubscription && <div className={classes.pricePeriod}>After trial</div>}
          <div className={classes.priceCircle}>${subscription?.price}</div>
          <div className={classes.pricePeriod}>per {subscription?.interval}</div>
        </div>
      </div>
      {subscription && (
        <div className={classes.paymentBlock}>
          <Elements {...stripeConfig}>
            <PayBlock />
          </Elements>
        </div>
      )}
    </PageWrap>
  );
}
