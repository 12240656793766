import { number, object, string } from 'prop-types';
import common from '@material-ui/core/colors/common';

const AmountChargeIcon = ({
  className = '',
  style = {},
  width = 46,
  height = 39,
  fillColor = '#898A8D',
  strokeColor = common.black,
  ...restProps
}) => (
  <svg
    {...restProps}
    style={style}
    className={className}
    width={width}
    height={height}
    fill='none'
    viewBox="30 90 1140.13 1020.19" 
  >
    <path d="m1168.8 748.8c-3.6016-16.801-14.398-31.199-28.801-39.602-15.602-8.3984-32.398-9.6016-49.199-2.3984l-234 94.801c1.1992 13.199 0 26.398-4.8008 38.398-15.602 48-64.801 75.602-112.8 66l-195.6-40.801c-15.602-3.6016-26.398-19.199-22.801-34.801 3.6016-15.602 19.199-26.398 34.801-22.801l195.6 40.801c19.199 4.8008 38.398-7.1992 44.398-26.398 6-18-2.3984-37.199-19.199-46.801l-198-102c-38.398-20.398-84-24-126-9.6016l-166.8 56.402v307.2l324 76.801c64.801 15.602 132 1.1992 186-37.199l351.6-259.2c17.996-14.402 26.398-37.203 21.598-58.801z" fill={fillColor} />
    <path d="m192 662.4h-127.2c-19.199 0-34.801 15.602-34.801 34.801v356.4c0 19.199 15.602 34.801 34.801 34.801h127.2c19.199 0 34.801-15.602 34.801-34.801v-357.6c0-19.199-15.602-33.602-34.801-33.602z" fill={fillColor} />
    <path d="m868.8 691.2c165.6 0 301.2-134.4 301.2-301.2s-135.6-300-301.2-300c-165.6 0-301.2 134.4-301.2 301.2s135.6 300 301.2 300zm24-273.6h-46.801c-51.602 0-93.602-42-93.602-93.602 0-50.398 40.801-91.199 90-92.398v-56.398h52.801v56.398c50.398 1.1992 90 42 90 92.398h-52.801c0-22.801-18-40.801-40.801-40.801h-46.801c-22.801 0-40.801 18-40.801 40.801s18 40.801 40.801 40.801h46.801c51.602 0 93.602 42 93.602 93.602 0 50.398-40.801 91.199-90 92.398v56.398h-52.801v-56.398c-50.398-1.1992-90-42-90-92.398h52.801c0 22.801 18 40.801 40.801 40.801h46.801c22.801 0 40.801-18 40.801-40.801-1.2031-22.801-19.203-40.801-40.801-40.801z" fill={fillColor} />
  </svg>
);

AmountChargeIcon.propTypes = {
  style: object,
  className: string,
  height: number,
  width: number,
  strokeColor: string,
  fillColor: string,
};

export default AmountChargeIcon;
